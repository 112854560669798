<template>
	<!--弹出窗口创建节点分组-->
	<div class="popup-container" v-if="popupShow">
		<div class="popup">
			<div class="title">
				<h2>{{ title }}</h2>
				<CloseOutlined @click="$emit('handleClose')" />
			</div>
			<div class="content">
				<slot></slot>
			</div>
			<div class="handle">
				<a-button class="handle-button" @click="$emit('handleCancel')">
					取消
				</a-button>
				<a-button
					class="handle-button"
					type="primary"
					@click="$emit('handleOk')"
				>
					确定
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import { CloseOutlined } from "@ant-design/icons-vue";
export default {
	props: {
		title: String,
		popupShow: Boolean,
	},
	emits: ["handleCancel", "handleOk", "handleClose"],
	name: "Popup",
	components: { CloseOutlined },
};
</script>

<style scoped>
.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(32, 33, 36, 0.6);
}
.popup {
	margin-top: 10vh;
	background: #ffffff;
	width: 640px;
}
.popup .title {
	display: flex;
	align-items: center;
	background: #24292e;
	padding: 10px 20px;
	color: #ffffff;
}
.popup .title h2 {
	color: #ffffff;
	font-size: 16px;
	flex: 1;
	margin: 0;
}
.popup .content {
	padding: 20px;
	overflow: auto;
}
.popup .handle {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	background: #f5f7fa;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.popup .handle .handle-button {
	margin-right: 10px;
}
.popup .tips {
	margin-top: 10px;
	font-size: 12px;
	color: #586069;
}
.popup .popup-item {
	margin-bottom: 10px;
}
</style>
