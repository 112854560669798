<template>
	<div class="z-steps">
		<div
			class="z-step"
			:class="{ action: Number(step) === 1, checked: Number(step) > 1 }"
			@click="$router.push('/admin/shop')"
		>
			1. 选择节点类型
		</div>
		<div
			class="z-step"
			:class="{ action: Number(step) === 2, checked: Number(step) > 2 }"
		>
			2. 配置节点&填写账户信息
		</div>
	</div>
</template>

<script>
export default {
	name: "ShopSteps",
	props: {
		step: {
			require: true,
			default: 1,
		},
	},
};
</script>

<style scoped>
.z-steps {
	display: flex;
	height: 50px;
	line-height: 50px;
	padding: 0 20px 0 10px;
	color: #586069;
	background-color: #ffffff;
	border-bottom: 1px #e1e4e8 solid;
	position: absolute;
	left: 0;
	right: 0;
	top: 100px;
	z-index: 999;
}
.z-step {
	font-size: 16px;
	font-weight: 500;
	padding: 0 4px;
	margin: 0 10px;
}
.action {
	color: #2ea44f;
	border-bottom: 4px #2ea44f solid;
}
.checked {
	color: #24292e;
	cursor: pointer;
}
</style>
