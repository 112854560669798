import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Admin from "@/admin/Admin.vue";
import Games from "@/admin/Games.vue";
import NodeGroups from "@/admin/NodeGroups.vue";
import NodeListByGroup from "@/admin/NodeListByGroup.vue";
import Node from "@/admin/Node.vue";
import AccountInfo from "@/admin/AccountInfo.vue";
import AccountNodesList from "@/admin/AccountNodesList.vue";
import AccountNodesTest from "@/admin/AccountNodesTest.vue";
import Account from "@/admin/Account.vue";
import AccountByUser from "@/admin/AccountByUser.vue";
import ShopGame from "@/admin/ShopGame.vue";
import ShopOrder from "@/admin/ShopOrder.vue";
import Shop from "@/admin/Shop.vue";
import Bill from "@/admin/Bill.vue";
import Overview from "@/admin/Overview.vue";
import Test from "@/views/Test.vue";
import User from "@/admin/User.vue";
import store from "@/store";
import Log from "@/admin/Log.vue";
import LogHost from "@/admin/LogHost.vue";
import LogByHost from "@/admin/LogByHost.vue";
import LogByHostAndZid from "@/admin/LogByHostAndZid.vue";
import { message } from "ant-design-vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/test",
		name: "Test",
		component: Test,
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Test.vue"),
	},
	{
		path: "/admin",
		name: "admin",
		meta: { login: true },
		component: Admin,
		children: [
			{
				path: "account",
				name: "account",
				component: Account,
				meta: { login: true },
			},
			{
				path: "edge",
				name: "edge",
				component: () => import("@/admin/edge/Edge.vue"),
				meta: { login: true },
				children: [
					{
						path: "manager",
						name: "edgeManager",
						component: () => import("@/admin/edge/Manager.vue"),
					},
					{
						path: "list",
						name: "edgeList",
						component: () => import("@/admin/edge/List.vue"),
					},
					{
						path: "setting/:controlIp/:controlIdPattern",
						name: "edgeSetting",
						component: () => import("@/admin/edge/EdgeSetting.vue"),
						props: true,
					},
				],
			},
			{
				path: "box",
				name: "box",
				component: () => import("@/admin/box/Box.vue"),
				meta: { login: true, admin: true },
				children: [
					{
						path: "",
						name: "gateway",
						component: () => import("@/admin/box/Gateway.vue"),
					},
					{
						path: "config",
						name: "config",
						meta: { login: true, admin: true },
						props: (route) => ({
							mac: route.query.mac,
							name: route.query.name,
						}),

						component: () => import("@/admin/box/Config.vue"),
					},
					{
						path: "shop",
						name: "boxShop",
						component: () => import("@/admin/box/Shop.vue"),
						meta: { login: true },
						children: [
							{
								path: "",
								name: "boxShopGame",
								component: () => import("@/admin/box/ShopGame.vue"),
								meta: { step: "1" },
							},
							{
								path: "order",
								name: "boxShopOrder",
								component: () => import("@/admin/box/ShopOrder.vue"),
								props: (route) => ({
									id: route.query.id,
									game: route.query.game,
								}),
								meta: { step: "2" },
							},
						],
					},
					{
						path: "manager",
						name: "boxManager",
						component: () => import("@/admin/box/Manager.vue"),
					},
					{
						path: "manager_city",
						name: "boxManagerCity",
						props: (route) => ({
							id: route.query.id,
							name: route.query.name,
						}),
						component: () => import("@/admin/box/ManagerCity.vue"),
					},
				],
			},
			{
				path: "account_by_user",
				name: "accountByUser",
				component: AccountByUser,
				meta: { login: true, admin: true },
				props: (route) => ({
					id: route.query.id,
					username: route.query.username,
				}),
			},
			{
				path: "user",
				name: "user",
				component: User,
				meta: { admin: true, login: true },
			},
			{
				path: "",
				name: "",
				component: Overview,
				meta: { login: true },
			},
			{
				path: "log",
				component: Log,
				meta: { login: true, admin: true },
				children: [
					{
						path: "",
						name: "logHost",
						component: LogHost,
					},
					{
						path: "log_by_host",
						name: "LogByHost",
						component: LogByHost,
						props: (route) => ({
							host: route.query.host,
							group: route.query.group,
							zidPattern: route.query.zidPattern,
						}),
					},
					{
						path: "log_by_host_zid",
						name: "LogByHostAndZid",
						component: LogByHostAndZid,
						props: (route) => ({
							host: route.query.host,
							group: route.query.group,
							zid: route.query.zid,
							start: route.query.start,
							end: route.query.end,
						}),
					},
				],
			},
			{
				path: "types",
				name: "types",
				component: Games,
				meta: { login: true, admin: true },
			},
			{
				path: "shop",
				name: "shop",
				component: Shop,
				meta: { login: true },
				children: [
					{
						path: "",
						name: "shopGame",
						component: ShopGame,
						meta: { step: "1" },
					},
					{
						path: "order",
						name: "ShopOrder",
						component: ShopOrder,
						props: (route) => ({
							id: route.query.id,
							game: route.query.game,
						}),
						meta: { step: "2" },
					},
				],
			},
			{
				path: "bill",
				name: "bill",
				component: Bill,
				meta: { login: true },
			},
			{
				path: "node_groups",
				name: "node_groups",
				component: NodeGroups,
				meta: { login: true, admin: true },
			},
			{
				path: "node_list_group",
				name: "node_list_group",
				component: NodeListByGroup,
				props: (route) => ({
					id: route.query.id,
					name: route.query.name,
				}),
				meta: { login: true, admin: true },
			},
			{
				path: "account_nodes_list/:id",
				name: "account_nodes_list",
				component: AccountNodesList,
				props: true,
				meta: { login: true },
			},
			{
				path: "account_nodes_test/:id",
				name: "account_nodes_test",
				component: AccountNodesTest,
				props: true,
				meta: { login: true },
			},
			{
				path: "node/:id",
				name: "node",
				component: Node,
				props: true,
				meta: { login: true, admin: true },
			},
			{
				path: "account_info/:id",
				name: "account_info",
				component: AccountInfo,
				props: true,
				meta: { login: true },
			},
		],
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to) => {
	if (to.meta.login) {
		if (!store.state.token) {
			message.warn("登录失效");
			router.replace("/").then();
		} else if (to.meta.admin) {
			if (!store.state.user.role || store.state.user.role !== "admin") {
				message.error("权限不足");
				router.replace("/admin").then();
			}
		}
	}
});

export default router;
