<template>
	<a-space class="p-4">
		<a-input-search
			class="w-80"
			placeholder="搜索ip/用户名/端口"
			size="large"
			v-model:value="keyword"
			@search="searchCustomLinks"
		/>
		<a-button size="large" @click="clearSearch">清除搜索</a-button>
	</a-space>
	<a-table
		:columns="columns"
		:data-source="customLinks"
		:pagination="{
			pageSize: 50,
			position: ['topLeft', 'bottomLeft'],
			total: 1050,
			current: current,
			onChange: (page) => getCustomLinks(page),
		}"
		:row-key="(record) => record.id"
	>
		<template #bodyCell="{ column, record }">
			<template v-if="column.key === 'username'">
				<span>{{ record.account.username }}</span>
			</template>
			<template v-if="column.key === 'ip'">
				<span>{{ record.gateway.ip }}</span>
			</template>
			<template v-if="column.key === 'city'">
				<span
					>{{ record.city?.province }}-{{ record.city?.city }} |
					{{ record.updateCity?.province }}-{{ record.updateCity?.city }}</span
				>
			</template>
			<template v-if="column.key === 'frequency'">
				<span>{{ record.account.update_frequency }}</span>
			</template>
			<template v-if="column.key === 'score'">
				{{ countArrayOne(record.checks) }}
			</template>
			<template v-if="column.key === 'operations'">
				<a-button type="primary" @click="refreshLink(record.id)">
					刷新
				</a-button>
			</template>
		</template>
	</a-table>
</template>

<script>
import { node } from "@/utils/api";
import { onMounted, ref } from "vue";
import { message, InputSearch, InputGroup, Space, Input } from "ant-design-vue";

export default {
	name: "NodeStatus",
	setup() {
		const columns = [
			{
				title: "id",
				dataIndex: "id",
			},
			{
				title: "ip",
				key: "ip",
			},
			{
				title: "control_id",
				dataIndex: "control_id",
			},
			{
				title: "所属用户",
				key: "username",
			},
			{
				title: "当前城市/更新城市",
				key: "city",
			},
			{
				title: "更新时间",
				dataIndex: "update_city_time",
			},
			{
				title: "更新频率",
				key: "frequency",
			},
			{
				title: "得分",
				key: "score",
			},
			// 增加一个按钮, 刷新
			{
				title: "操作",
				key: "operations",
			},
		];

		// 处理 节点数据
		const groups = ref([]);
		const disableNodesCount = ref(0);
		function handleNodeStatus(data) {
			const _groups = data.groups;
			const _results = data.results;

			let _disableNodesCount = 0;
			_groups.map((group) => {
				const _groupResults = _results.filter(
					(result) => result.group === group.id
				);
				// 节点压力
				let _groupUsers = 0;
				// 分组 可用节点 不可用节点
				let _groupEnable = 0;
				let _groupDisable = 0;
				_groupResults.forEach((groupResult) => {
					groupResult.status ? _groupEnable++ : _groupDisable++;
					_groupUsers += Object.keys(groupResult.data).length;
				});
				group.enable = _groupEnable;
				group.disable = _groupDisable;
				group.results = _groupResults;
				group.users = _groupUsers;

				_disableNodesCount += group.disable;
				return group;
			});
			disableNodesCount.value = _disableNodesCount;
			groups.value = _groups;
		}

		// 获得 节点数据
		async function getNodeStatus() {
			// 获得节点状态
			const { data } = await node.getNodeStatus();
			// 处理节点状态
			if (!data) {
				message.info("节点状态不存在，点击刷新初始化");
			} else {
				handleNodeStatus(data);
			}
		}

		const customLinks = ref([]);

		const cities = ref([]);

		const current = ref(1);

		const keyword = ref("");

		async function getCustomLinks(page = 1) {
			current.value = page;
			const { data } = await node.getCustomLinks(page);
			// 使用countArrayOne函数计算得分,并将得分绑定到score上，数组使用score的升序排序
			customLinks.value = data
				.map((item) => {
					item.score = countArrayOne(item.checks);
					return item;
				})
				.sort((a, b) => a.score - b.score);
		}

		async function searchCustomLinks() {
			if (keyword.value === "") {
				getCustomLinks(current.value);
			} else {
				const { data } = await node.searchCustomLinks(keyword.value);
				customLinks.value = data;
			}
		}

		function clearSearch() {
			keyword.value = "";
			getCustomLinks(current.value);
		}

		// 查找数组中有多少'1'
		function countArrayOne(arr) {
			let count = 0;
			arr.forEach((item) => {
				if (item === "1") {
					count++;
				}
			});
			return count - 1;
		}

		async function refreshLink(id) {
			const response = await node.adminRefreshLink(id);
			if (response.status === 200 || response.status === 201) {
				message.success("刷新成功");
			} else {
				message.error("刷新失败");
			}
		}

		// 初始化 节点数据
		async function initNodeStatus() {
			await node.initNodeStatus();
			await getNodeStatus();
		}
		onMounted(() => {
			getCustomLinks();
		});
		return {
			columns,
			groups,
			customLinks,
			disableNodesCount,
			initNodeStatus,
			cities,
			getCustomLinks,
			current,
			keyword,
			searchCustomLinks,
			clearSearch,
			countArrayOne,
			refreshLink,
		};
	},
};
</script>

<style scoped>
.operations {
	padding: 10px;
}
</style>
