<template>
	<module-title title="账单"></module-title>
	<a-space class="operations">
		<a-radio-group
			v-model:value="billType"
			@change="getBills"
			button-style="solid"
		>
			<a-radio-button value="all">全部</a-radio-button>
			<a-radio-button value="recharge">收入</a-radio-button>
			<a-radio-button value="expense">消费</a-radio-button>
		</a-radio-group>
		<a-range-picker v-model:value="timeRange" @change="getBills" />
		<a-input-search
			v-model:value="username"
			placeholder="输入用户名"
			@search="getBills"
		></a-input-search>
	</a-space>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="bills"
			:row-key="(record) => record.id"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
			}"
			@change="tableChange"
			size="middle"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'user'">
					{{ record.user.username }}
				</template>
				<template v-else-if="column.key === 'direction'">
					<a-tag v-if="record.direction === 'income'" color="green">收入</a-tag>
					<a-tag v-else color="blue">消费</a-tag>
				</template>
				<template v-else-if="column.key === 'coin'">
					<span
						:class="{
							red: record.direction === '扣费',
							green: record.direction !== '扣费',
						}"
						>{{ record.coin }}</span
					>
				</template>
			</template>
		</a-table>
	</div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
import { useStore } from "vuex";
import { bill } from "@/utils/api";
import { onMounted, reactive, ref } from "vue";
import dayjs from "dayjs";

export default {
	name: "Bill",
	components: { ModuleTitle },
	setup() {
		const store = useStore();
		const columns = [
			{
				title: "时间",
				dataIndex: "create_time",
			},
			{
				title: "交易类型",
				key: "direction",
			},
			{
				title: "相关描述",
				dataIndex: "remark",
			},
			{
				title: "用户",
				key: "user",
			},
			{
				title: "变动金额",
				key: "coin",
			},
			{
				title: "变动前",
				dataIndex: "wallet_before",
			},
			{
				title: "变动后",
				dataIndex: "wallet_after",
			},
		];
		const bills = ref([]);
		const listLoading = ref(true);
		const billType = ref("all");
		const dateFormat = ["YYYY/MM/DD", "YYYY/MM/DD"];
		const timeRange = ref([]); // 时间范围
		// username
		const username = ref("");

		// 查找账单列表 分页
		const pagination = reactive({
			current: 1,
			pageSize: 30,
			total: 50,
		});
		async function getBills() {
			listLoading.value = true;
			await bill
				.listAdmin(
					pagination.current,
					pagination.pageSize,
					billType.value,
					timeRange.value.length === 2
						? timeRange.value[0].format("YYYY/MM/DD")
						: "",
					timeRange.value.length === 2
						? timeRange.value[1].format("YYYY/MM/DD")
						: "",
					username.value
				)
				.then(({ data }) => {
					console.log(data);
					pagination.total = data.total;
					pagination.pageSize = Number(data.limit);
					bills.value = data.data;
				});
			listLoading.value = false;
		}

		// 响应分页
		function tableChange(pageChange) {
			pagination.current = pageChange.current;
			pagination.pageSize = pageChange.pageSize;
			getBills();
		}

		onMounted(() => {
			getBills();
		});
		return {
			columns,
			store,
			bills,
			billType,
			getBills,
			pagination,
			tableChange,
			timeRange,
			dateFormat,
			username,
		};
	},
};
</script>

<style scoped>
.green {
	font-weight: 500;
	color: #2ea44f;
}

.red {
	font-weight: 500;
	color: #0366d6;
}
</style>
