<template>
	<module-title back="1" title="测试账户代理">
		用户名: {{ username }} | 节点类型: {{ game }}
	</module-title>
	<div class="tips">
		<a-alert message="测试延迟不作为延迟依据，仅测试是否联通" type="info" />
	</div>
	<div class="operations">
		<a-button type="primary" @click="checkProxyByAccount" class="button">
			<ThunderboltFilled />
			测试所有代理连通性
		</a-button>
	</div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="list"
			:row-key="(record) => record.id"
			:loading="tableLoading"
			size="small"
			:pagination="{ pageSize: 200 }"
		>
			<template #socks5="{ record }">
				<span v-if="record.socks5">
					<span v-if="record.socks5.status" class="green">
						{{ record.socks5.delay }}
					</span>
					<span v-else class="red"> 超时或失败 </span>
				</span>
				<span v-else>未测试</span>
			</template>
			<template #http="{ record }">
				<span v-if="record.http">
					<span v-if="record.http.status" class="green">
						{{ record.http.delay }}
					</span>
					<span v-else class="red"> 超时或失败 </span>
				</span>
				<span v-else>未测试</span>
			</template>
			<template #action="{ record }">
				<a-button
					@click="checkProxyLink(record.id, 'http', record.ip, record.port)"
					style="margin-right: 10px"
					><ThunderboltFilled />HTTP</a-button
				>
				<a-button
					@click="checkProxyLink(record.id, 'ss5', record.ip, record.port)"
					><ThunderboltFilled />SOCKS5</a-button
				>
			</template>
		</a-table>
	</div>
</template>

<script>
import { ThunderboltFilled } from "@ant-design/icons-vue";
import { account, status } from "@/utils/api";
import { onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import ModuleTitle from "@/components/ModuleTitle";

export default {
	name: "AccountNodesTest",
	props: { id: String },
	components: {
		ModuleTitle,
		ThunderboltFilled,
	},
	setup(props) {
		const columns = [
			{ title: "ID", dataIndex: "id" },
			{
				title: "位置",
				dataIndex: "group",
			},
			{
				title: "IP",
				dataIndex: "ip",
			},
			{
				title: "端口",
				dataIndex: "zid",
			},
			{
				title: "http",
				slots: { customRender: "http" },
			},
			{
				title: "socks5",
				slots: { customRender: "socks5" },
			},
			{
				title: "操作",
				slots: { customRender: "action" },
			},
		];
		const list = ref([]);
		const username = ref("");
		const password = ref("");
		const game = ref("");
		const tableLoading = ref(false);
		/**
		 * 获得账户下节点列表
		 * @return {Promise<void>}
		 */
		async function getAccountNodesList() {
			tableLoading.value = true;
			account
				.getAccountNodesList(props.id)
				.then((res) => {
					tableLoading.value = false;
					if (res.data.code === 0) {
						({
							list: list.value,
							name: username.value,
							password: password.value,
							game: game.value,
						} = res.data.data);
					}
				})
				.catch((err) => {
					console.log(err);
					tableLoading.value = false;
				});
		}

		/**
		 * 测试单个代理连通性
		 * @param id
		 * @param type
		 * @param ip
		 * @param port
		 * @return {Promise<void>}
		 */
		async function checkProxyLink(id, type, ip, port) {
			if (type === "http") {
				status
					.checkProxyLink("http", ip, username.value, password.value, port)
					.then((res) => {
						if (res.data.code === 0) {
							list.value[list.value.findIndex((item) => item.id === id)].http =
								{
									status: true,
									delay: res.data.data.delay,
								};
						} else {
							list.value[list.value.findIndex((item) => item.id === id)].http =
								{
									status: false,
									delay: res.data.data.delay,
								};
						}
					});
			} else {
				status
					.checkProxyLink("ss5", ip, username.value, password.value, port)
					.then((res) => {
						console.log(res);
						if (res.data.code === 0) {
							list.value[
								list.value.findIndex((item) => item.id === id)
							].socks5 = {
								status: true,
								delay: res.data.data.delay,
							};
						} else {
							list.value[
								list.value.findIndex((item) => item.id === id)
							].socks5 = {
								status: false,
								delay: res.data.data.delay,
							};
						}
					});
			}
		}
		async function checkProxyByAccount() {
			await status.checkProxyLinkByAccount(props.id).then((res) => {
				if (res.data.code === 0) {
					for (const response of res.data.data) {
						if (response.protocol === "ss5") {
							list.value[
								list.value.findIndex((item) => item.id === response.id)
							].socks5 = {
								status: response.status,
								delay: response.delay,
							};
						} else {
							list.value[
								list.value.findIndex((item) => item.id === response.id)
							].http = {
								status: response.status,
								delay: response.delay,
							};
						}
					}
				} else {
					message.error("检查代理失败或未响应");
				}
			});
		}
		onMounted(() => {
			getAccountNodesList().then();
		});
		return {
			columns,
			tableLoading,
			list,
			username,
			game,
			checkProxyLink,
			checkProxyByAccount, // 检查账户下所有代理
		};
	},
};
</script>

<style scoped>
.green {
	color: #2ea44f;
}
.red {
	color: red;
}
</style>
