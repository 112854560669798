<template>
	<div class="accounts">
		<module-title title="节点账户">
			<a-dropdown>
				<template #overlay>
					<a-menu @click="changeAccountListType">
						<a-menu-item key="expire"> 7天内到期的账户</a-menu-item>
						<a-menu-item key="block"> 封禁的账户</a-menu-item>
						<a-menu-item key="all"> 全部节点账户</a-menu-item>
					</a-menu>
				</template>
				<a-button>
					{{ accountListType }}
					<DownOutlined />
				</a-button>
			</a-dropdown>
		</module-title>
		<div class="filters" v-show="filtersShow">
			<div class="sorts">
				<div class="sort-module">
					<h4>按类型筛选</h4>
					<div class="sort-list">
						<a @click="filterAccountList('all')">全部</a>
						<a
							@click="filterAccountList(keyword)"
							v-for="keyword in filterKeys"
							:key="keyword"
						>
							{{ keyword }}
						</a>
					</div>
				</div>
				<div class="sort-module">
					<h4>按用户筛选</h4>
					<div class="sort-list">
						<a @click="filterAccountListByUsername('all')">全部</a>
						<a
							@click="filterAccountListByUsername(keyword)"
							v-for="keyword in filterKeysUsername"
							:key="keyword"
						>
							{{ keyword }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="operations">
			<a-button type="primary" class="button">
				<SisternodeOutlined />
				新建节点账户
			</a-button>
			<a-button class="button" @click="filtersShow = !filtersShow">
				更多筛选选项
			</a-button>
			<a-button class="button" @click="getAccountList">
				<CloudSyncOutlined />
				刷新
			</a-button>
			<a-button class="button" disabled>
				<DisconnectOutlined />
				封禁
			</a-button>
			<a-button class="button" disabled>
				<ThunderboltOutlined />
				检测
			</a-button>
			<!--      <a-button class="button">-->
			<!--        更多操作-->
			<!--        <DownOutlined />-->
			<!--      </a-button>-->
			<div class="search">
				<a-input-search
					placeholder="输入用户账号检索"
					:allow-clear="true"
					style="width: 30%"
					v-model:value="keyword"
					@search="searchKeyword"
				/>
			</div>
		</div>
		<div class="list">
			<a-table
				:columns="columns"
				:data-source="accounts"
				@change="handlePageChange"
				:row-key="(record) => record.id"
				:pagination="{
					current: page,
					pageSize: pageSize,
					total: total,
				}"
				:rowSelection="{ columnWidth: 40 }"
				:rowClassName="
					(record, index) => (index % 2 === 1 ? 'table-striped' : null)
				"
				size="middle"
				:loading="listLoading"
			>
				<template #bodyCell="{ column, record }">
					<template v-if="column.index === 'id'">
						<b class="blue">{{ record.username }}</b>
						<span class="gray">@{{ record.user.username }}</span>
					</template>
					<template v-if="column.index === 'state'">
						<span class="state normal" v-if="record.state === 'normal'">
							正常
						</span>
						<span class="state previewing" v-if="record.state === 'previewing'">
							预检
						</span>
						<span class="state renew" v-if="record.state === 'renew'">
							过期
						</span>
						<span class="state ghost" v-if="record.state === 'ghost'">
							删除
						</span>
						<span class="state block" v-if="record.state === 'block'">
							封禁
						</span>
					</template>
					<template v-if="column.index === 'game'">
						{{ record.game.name }}
					</template>
					<template v-if="column.index === 'time'">
						{{ record.expire_time }}
					</template>
					<template v-if="column.index === 'nodes'">
						{{ record.nodes.length }}
					</template>
					<template v-if="column.index === 'action'">
						<a
							class="action-button"
							@click="$router.push(`/admin/account_info/${record.id}`)"
							type="link"
						>
							查看/导出
						</a>
						<span
							v-if="
								store.state.user.role !== 'admin' && record.state === 'block'
							"
						>
							账户已封禁,请联系管理员
						</span>
						<a
							class="action-button"
							@click="$router.push(`/admin/account_nodes_list/${record.id}`)"
							type="link"
						>
							编辑
						</a>
						<a
							class="action-button"
							type="link"
							@click="$router.push(`/admin/account_nodes_test/${record.id}`)"
							v-if="store.state.user.role === 'admin'"
						>
							检测
						</a>
						<a-popconfirm
							title="您要封禁这个账户么?"
							ok-text="确认封禁"
							cancel-text="取消"
							@confirm="blockAccount(record.id, 'block')"
							v-if="store.state.user.role === 'admin'"
						>
							<a class="action-button" v-if="record.state !== 'block'">
								封禁
							</a>
						</a-popconfirm>
						<a-popconfirm
							title="您要解封这个账户么?"
							ok-text="确认解封"
							cancel-text="取消"
							@confirm="blockAccount(record.id, 'relieve')"
							v-if="store.state.user.role === 'admin'"
						>
							<a class="action-button" v-if="record.state === 'block'">
								解封
							</a>
						</a-popconfirm>
						<!--修改用户最大可用更新次数-->
						<a-popconfirm
							title="您要删除这个账户么?"
							ok-text="确认删除"
							cancel-text="取消"
							@confirm="
								() => {
									record.type === 'custom'
										? delCustomAccount(record.id)
										: delAccount(record.id);
								}
							"
						>
							<a class="action-button" v-if="store.state.user.role === 'admin'">
								删除
							</a>
						</a-popconfirm>
					</template>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle";
import {
	DownOutlined,
	DisconnectOutlined,
	ThunderboltOutlined,
	CloudSyncOutlined,
	SisternodeOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { onMounted, reactive, ref } from "vue";
import { account } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
	name: "Accounts",
	components: {
		ModuleTitle,
		DownOutlined,
		DisconnectOutlined,
		ThunderboltOutlined,
		CloudSyncOutlined,
		SisternodeOutlined,
	},
	setup() {
		const store = useStore();
		const filterKeys = ref([]);
		const filterKeysUsername = ref([]);
		const filtersShow = ref(false);
		const columns = reactive([
			{
				title: "ID/名称",
				index: "id",
			},
			{
				title: "状态",
				index: "state",
			},
			{
				title: "类型",
				dataIndex: "game_id",
				index: "game",
				filters: filterKeys.value,
				onFilter: (value, record) => record.game_id === value,
				filterMultiple: false,
			},
			{
				title: "节点数",
				index: "nodes",
			},
			{
				title: "到期时间",
				index: "time",
				sorter: (a, b) =>
					new Date(a.expire_time).getTime() - new Date(b.expire_time).getTime(),
			},
			{
				title: "操作",
				index: "action",
			},
		]);

		const accountsDefault = ref([]);
		const accounts = ref([]);
		const keyword = ref("");
		const listLoading = ref(false);

		function handlePageChange(pagination, filters, sorter) {
			page.value = pagination.current;
			pageSize.value = pagination.pageSize;
			getAccountList();
		}

		const page = ref(1);
		const pageSize = ref(30);
		const total = ref(0);

		// 获得账户列表
		async function getAccountList(type) {
			listLoading.value = true;
			console.log(accountListTypeKey.value);
			const response = await account.getAccountListV2(
				page.value,
				pageSize.value,
				accountListTypeKey.value
			);
			if (response.status === 200) {
				accounts.value = response.data.accounts;
				total.value = response.data.total;
			}
			listLoading.value = false;
		}

		// 筛选账户
		function filterAccountList(keyword) {
			if (keyword === "all") {
				accounts.value = accountsDefault.value;
			} else {
				accounts.value = accountsDefault.value.filter(
					(account) => account.game_id === keyword
				);
			}
		}

		// 筛选账户通过username
		function filterAccountListByUsername(keyword) {
			if (keyword === "all") {
				accounts.value = accountsDefault.value;
			} else {
				accounts.value = accountsDefault.value.filter(
					(account) => account?.user.username === keyword
				);
			}
		}

		// 封禁账户
		async function blockAccount(id, action) {
			await account
				.blockAccount(id, action)
				.then((res) => {
					if (res.data.code === 0) {
						message.success(`${action}用户成功`);
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => message.error(err));
			await getAccountList().then();
		}

		// 删除账户
		async function delAccount(id) {
			await account.deleteAccount(id).then((res) => {
				if (res.data.code === 0) {
					message.success(res.data.message);
					getAccountList().then();
				}
			});
		}

		// 删除定制账户
		async function delCustomAccount(id) {
			await account.deleteCustomAccount(Number(id)).then(() => {
				getAccountList();
			});
		}

		/**
		 * 筛选关键词
		 */
		async function searchKeyword() {
			if (keyword.value === "") {
				await getAccountList();
			} else {
				const response = await account.searchAccount(keyword.value);
				if (response.status === 200) {
					accounts.value = response.data;
				}
			}
		}

		// 节点账户选项
		const accountListType = ref("全部节点账户");
		const accountListTypeKey = ref("all");

		async function changeAccountListType(event) {
			if (accountListTypeKey.value === event.key) {
				return true;
			}
			switch (event.key) {
				case "expire":
					accountListType.value = "七天内到期账户";
					accountListTypeKey.value = "expire";
					await getAccountList("expire").then();
					message.success("七天内到期账户");
					break;
				case "block":
					accountListType.value = "封禁的账户";
					accountListTypeKey.value = "block";
					await getAccountList("block").then();
					message.success("封禁的账户");
					break;
				case "all":
					accountListType.value = "七天内到期账户";
					accountListTypeKey.value = "all";
					await getAccountList().then();
					message.success("全部节点账户");
					break;
				default:
					break;
			}
		}

		// 查询即将到期的用户
		onMounted(() => {
			getAccountList().then();
		});
		return {
			columns,
			accounts,
			delAccount,
			delCustomAccount,
			keyword,
			searchKeyword,
			getAccountList,
			store,
			blockAccount,
			changeAccountListType,
			accountListType,
			listLoading,
			filterKeys,
			filterAccountList,
			filterKeysUsername,
			filtersShow,
			filterAccountListByUsername,
			handlePageChange,
			page,
			pageSize,
			total,
		};
	},
};
</script>

<style scoped>
.accounts {
	min-width: 850px;
}

.operations {
	margin-top: 10px;
	padding: 0 20px;
	display: flex;
	flex-wrap: wrap;
}

.search {
	flex: 1;
	text-align: right;
}

.list {
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
	margin: 10px 20px;
}

.table-striped {
	background-color: rgb(250, 251, 252);
}

.username {
	padding-left: 10px;
}

.blue {
	font-weight: 500;
}

.action-button {
	color: rgb(3, 102, 214);
	margin-right: 10px;
}

.state {
	color: #2ea44f;
}

.previewing {
	color: rgb(251, 188, 4);
}

.block {
	color: rgb(217, 48, 37);
}

.renew {
	color: #0366d6;
}

.filters {
	padding: 0 20px 20px 20px;
}

.filters .sort-module {
	padding-top: 20px;
}

.filters .sort-module h4 {
	color: #2ea44f;
}

.filters .sort-module .sort-list {
	display: flex;
	flex-wrap: wrap;
}

.filters a {
	display: block;
	padding: 2px 6px;
	width: 120px;
	margin: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-radius: 2px;
}

.filters a:hover {
	background: #2ea44f;
	color: #ffffff;
}

.gray {
	color: #586069;
	font-size: 12px;
}
</style>
