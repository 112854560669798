import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
	state: {
		user: {
			id: "",
			username: "",
			role: "",
			create_time: "",
			update_time: "",
			token_timestamp: "",
			state: "",
			wallet: {
				coin: 0,
				state: "",
			},
		},
		token: "",
		pagination: {
			pageSize: 100, // 每页最大数据行数
		},
	},
	mutations: {
		login(state, payload) {
			state.user = payload.user;
			state.token = payload.token;
		},
		quit(state) {
			state.user = {
				id: "",
				username: "",
				role: "",
				create_time: "",
				update_time: "",
				token_timestamp: "",
				state: "",
				wallet: {
					coin: 0,
					state: "",
				},
			};
			state.token = "";
			localStorage.clear();
		},
		updateCoin(state, payload) {
			state.user.wallet.coin = payload;
		},
	},
	actions: {},
	modules: {},
	plugins: [createPersistedState()],
});
