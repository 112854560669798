<template>
	<module-title :title="name" back="1"></module-title>
	<div class="operation">
		<a-button class="operation-button" @click="getNodeListByGroupId">
			<ReloadOutlined />
		</a-button>
		<a-button
			type="primary"
			class="operation-button"
			@click="createNodeShow = true"
		>
			<PlusOutlined />创建节点
		</a-button>
		<a-button class="operation-button" @click="uploadConfigShow = true">
			<CloudUploadOutlined />上传节点配置文件
		</a-button>
		<a-button class="operation-button" @click="loadNodeGroupConfig">
			<RobotOutlined />加载节点配置文件
		</a-button>
		<!--:TODO 搜索节点-->
		<a-input-search placeholder="输入搜索的节点类型" style="width: 200px" />
	</div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="nodeList"
			:row-key="(record) => record.id"
			:pagination="store.state.pagination"
			size="small"
		>
			<template #action="{ record }">
				<a-button @click="$router.push(`/admin/node/${record.id}`)" type="link"
					>查看/编辑</a-button
				>
				<a-button type="link" @click="updateNode(record)">更新节点</a-button>
				<a-button type="link" @click="restartNode(record.id)"
					>重启节点</a-button
				>
			</template>
		</a-table>
	</div>
	<!--弹出窗口新增节点-->
	<div class="popup-container" v-if="createNodeShow">
		<div class="popup">
			<div class="title">
				<h2>创建节点</h2>
				<a-button type="link" @click="createNodeShow = false">
					<CloseOutlined />
				</a-button>
			</div>
			<div class="content">
				<a-form :labelCol="{ span: 5 }" :wrapperCol="{ offset: 1, span: 10 }">
					<a-form-item label="节点分组" class="popup-item">{{
						name
					}}</a-form-item>
					<a-form-item
						label="节点IP"
						class="popup-item"
						v-bind="validateInfos.ip"
					>
						<a-input v-model:value="createNodeDto.ip" />
					</a-form-item>
					<a-form-item
						label="主控IP"
						class="popup-item"
						v-bind="validateInfos.controlId"
					>
						<a-input v-model:value="createNodeDto.controlIp" />
					</a-form-item>
					<a-form-item
						label="主控IP端口"
						class="popup-item"
						v-bind="validateInfos.controlPort"
					>
						<a-input v-model:value="createNodeDto.controlPort" />
					</a-form-item>
					<a-form-item
						label="主控IP内部ID"
						class="popup-item"
						v-bind="validateInfos.controlId"
					>
						<a-input v-model:value="createNodeDto.controlId" />
					</a-form-item>
				</a-form>
			</div>
			<div class="handle">
				<a-button class="handle-button" @click="createNodeShow = false"
					>取消</a-button
				>
				<a-button @click="reset" class="handle-button">重置</a-button>
				<a-button type="primary" class="handle-button" @click="submit"
					>创建节点</a-button
				>
			</div>
		</div>
	</div>
	<!--上传配置文件-->
	<popup
		:popup-show="uploadConfigShow"
		:title="`上传 [${name}] 的配置文件,文件名应为 ${id}.xlsx`"
		@handleCancel="uploadConfigShow = false"
		@handleClose="uploadConfigShow = false"
	>
		<the-upload></the-upload>
	</popup>
	<!--更新节点-->
	<popup
		:popup-show="updateShow"
		:title="更新节点信息"
		@handle-cancel="updateShow = false"
	></popup>
</template>

<script>
import { node } from "@/utils/api";
import { onMounted, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import { useForm } from "@ant-design-vue/use";
import {
	ReloadOutlined,
	PlusOutlined,
	CloseOutlined,
	CloudUploadOutlined,
	RobotOutlined,
} from "@ant-design/icons-vue";
import Popup from "@/components/Popup";
import TheUpload from "@/components/TheUpload";
import { useStore } from "vuex";
import ModuleTitle from "@/components/ModuleTitle";

export default {
	name: "NodeListByGroup",
	props: {
		id: String,
		name: String,
	},
	setup(props) {
		const store = useStore();
		const columns = [
			{
				title: "ID",
				key: "id",
				dataIndex: "id",
			},
			{
				title: "IP",
				key: "ip",
				dataIndex: "ip",
			},
			{
				title: "控制IP",
				key: "controlIp",
				dataIndex: "control_ip",
			},
			{
				title: "控制IP-ID",
				key: "controlId",
				dataIndex: "control_id",
			},
			{
				title: "修改时间",
				key: "updateTime",
				dataIndex: "update_time",
			},
			{
				title: "操作",
				slots: { customRender: "action" },
			},
		];
		/**
		 * 获得groupID下的节点列表
		 */
		const nodeList = ref([]);
		async function getNodeListByGroupId() {
			const data = await node.getNodeList(props.id).then((res) => res.data);
			if (data.code === 0) {
				nodeList.value = data.data;
			} else {
				// ：TODO 处理请求失败
			}
		}

		/**
		 * 创建节点
		 */

		// 表单校验
		const createNodeShow = ref(false); //创建节点窗口
		const createNodeDto = reactive({
			ip: "",
			controlIp: "",
			controlId: "",
			controlPort: "",
		});
		const rulesRef = reactive({
			ip: [{ required: true, message: "ip不能为空" }],
			controlIp: [{ required: true, message: "主控ip不能为空" }],
			controlPort: [{ required: true, message: "端口不能为空" }],
			controlId: [{ required: true, message: "对应id不能为空" }],
		});
		const { resetFields, validate, validateInfos } = useForm(
			createNodeDto,
			rulesRef
		);
		// 重置表单方法
		function reset() {
			resetFields();
		}
		/**
		 * 创建节点方法
		 * @returns {Promise<void>}
		 */
		async function createNode(createNodeDto) {
			// 请求创建
			const data = await node
				.createNode(
					createNodeDto.ip,
					createNodeDto.controlIp,
					createNodeDto.controlPort,
					createNodeDto.controlId,
					createNodeDto.groupId
				)
				.then((res) => res.data);
			// 判断返回
			if (data.code === 0) {
				message.success(data.message);
				reset();
				createNodeShow.value = false;
			} else {
				message.error(data.message);
			}
			return data;
		}
		// 提交表单
		async function submit() {
			validate()
				.then(() => {
					// 获取表单信息，组装并调用创建节点方法
					// 添加收到的group_id
					// 判断groupId是否为空
					if (props.id === undefined) {
						message.error("信息丢失，请返回上一页重新操作！");
					} else {
						createNodeDto.groupId = props.id;
						createNode(createNodeDto).then();
					}
				})
				.catch((err) => {
					console.log("error", err);
				});
		}
		// 上传配置文件窗口
		const uploadConfigShow = ref(false);
		// 加载配置文件
		async function loadNodeGroupConfig() {
			node
				.loadNodeGroupConfig(props.id)
				.then((res) => {
					message.success(`${props.name}${res.data.message}`);
				})
				.catch((err) => console.log(err));
		}

		// 更新节点
		const updateShow = ref(false);
		async function updateNode(record) {
			updateShow.value = true;
		}
		// 重启节点
		async function restartNode(id) {
			await node.restart(id);
		}
		onMounted(() => {
			getNodeListByGroupId().then();
		});
		return {
			nodeList,
			columns,
			getNodeListByGroupId,
			createNodeShow,
			createNodeDto,
			submit,
			validateInfos,
			reset,
			uploadConfigShow,
			loadNodeGroupConfig,
			store, // vuexStore
			updateNode,
			updateShow,
			restartNode,
		};
	},
	components: {
		ModuleTitle,
		TheUpload,
		Popup,
		ReloadOutlined,
		PlusOutlined,
		CloseOutlined,
		CloudUploadOutlined,
		RobotOutlined,
	},
};
</script>

<style scoped>
.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(32, 33, 36, 0.6);
}

.popup {
	margin-top: 200px;
	background: #ffffff;
	width: 500px;
}

.popup .title {
	display: flex;
	align-items: center;
	background: #141f29;
	padding: 10px 20px;
	color: #ffffff;
}

.popup .title h2 {
	color: #ffffff;
	font-size: 16px;
	flex: 1;
	margin: 0;
}

.popup .content {
	padding: 20px;
}

.popup .handle {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	background: #f5f7fa;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.popup .handle .handle-button {
	margin-right: 10px;
}

.popup .tips {
	margin-top: 10px;
	font-size: 12px;
	color: #586069;
}

.popup .popup-item {
	margin-bottom: 10px;
}
</style>
