
import { defineComponent } from "vue";
import {
	DashboardOutlined,
	// NodeExpandOutlined,
	SisternodeOutlined,
	PartitionOutlined,
	// DownOutlined,
	CodeOutlined,
	TeamOutlined,
	AccountBookOutlined,
	LockOutlined,
	SmileOutlined,
	ReadOutlined,
	NumberOutlined,
	BuildOutlined,
	FundProjectionScreenOutlined,
	HomeOutlined,
	RocketOutlined,
	CodepenCircleFilled,
	DatabaseOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
export default defineComponent({
	name: "TheSidebar",
	components: {
		DashboardOutlined,
		// NodeExpandOutlined,
		SisternodeOutlined,
		PartitionOutlined,
		RocketOutlined,
		CodeOutlined,
		// DownOutlined,
		TeamOutlined,
		AccountBookOutlined,
		LockOutlined,
		SmileOutlined,
		ReadOutlined,
		NumberOutlined,
		BuildOutlined,
		FundProjectionScreenOutlined,
		HomeOutlined,
		CodepenCircleFilled,
		DatabaseOutlined,
	},
	setup() {
		const store = useStore();
		return {
			store,
		};
	},
});
