<template>
	<div class="title">
		<div v-if="back === '1'">
			<a @click="$router.go(-1)" type="link" class="back"
				><ArrowLeftOutlined
			/></a>
		</div>

		<h2 class="menu-title">{{ title }}</h2>
		<div class="menu">
			<slot />
		</div>
	</div>
</template>

<script>
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
export default {
	props: ["title", "back"],
	name: "ModuleTitle",
	components: { ArrowLeftOutlined },
};
</script>

<style scoped>
.title {
	height: 50px;
	line-height: 50px;
	background: #ffffff;
	padding-left: 20px;
	display: flex;
	width: 100%;
	align-items: baseline;
	justify-content: flex-start;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	z-index: 888;
}
.title .back {
	color: #2ea44f;
	padding: 5px;
	margin-top: -5px;
	margin-left: -5px;
	margin-right: 10px;
	font-weight: 700;
}
.title .back:hover {
	background-color: #f2f2f2;
}
.title h2 {
	font-size: 16px;
	color: #24292e;
	font-weight: 700;
}
.menu-title {
	overflow: hidden;
	white-space: nowrap;
}
.menu {
	margin-left: 10px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
}
</style>
