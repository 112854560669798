<template>
	<a-upload-dragger
		name="file"
		:multiple="true"
		action="https://z.51tcp.com/node/upload"
		:withCredentials="true"
		:headers="{ Authorization: `Bearer ${store.state.token}` }"
	>
		<p class="ant-upload-drag-icon">
			<inbox-outlined />
		</p>
		<p class="ant-upload-text">点击或拖动文件上传</p>
		<p class="ant-upload-hint">
			上传会覆盖原有配置文件，加载配置文件后，以IP为主键，已有节点以新配置覆盖，新节点增加。请谨慎。
		</p>
	</a-upload-dragger>
</template>

<script>
import { InboxOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
export default {
	name: "TheUpload",
	components: {
		InboxOutlined,
	},
	setup() {
		const store = useStore();
		return {
			store,
		};
	},
};
</script>

<style scoped></style>
