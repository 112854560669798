<template>
	<header class="header">
		<div class="logo">
			<img src="@/assets/img/logo.png" alt="logo" height="30" />
		</div>
		<nav class="nav">
			<!--          <a href="#" class="link">首页</a>-->
			<!--          <a href="#" class="link">产品</a>-->
			<!--          <a href="#" class="link">帮助</a>-->
			<!--          <a href="#" class="link">关于</a>-->
		</nav>
		<div class="entrance" v-if="store.state.token === ''">
			<a-button type="primary" @click="loginShow = true">登录</a-button>
		</div>
		<div class="entrance" v-else>
			<span class="name">
				{{ store.state.user.username }}
			</span>
			|
			<span class="coin">{{ store.state.user.wallet.coin }} 鲸币</span>
			<a-button type="primary" @click="$router.push('/admin')">控制台</a-button>
			<a-button type="link" class="link-button" @click="store.commit('quit')"
				>退出登录</a-button
			>
		</div>
	</header>
	<div class="login-container" v-if="loginShow">
		<div ref="login">
			<TheLogin />
		</div>
	</div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import TheLogin from "@/components/TheLogin.vue";
import { useStore } from "vuex";
export default defineComponent({
	name: "TheHeader",
	components: { TheLogin },
	setup() {
		const store = useStore();
		const loginShow = ref(false);
		const login = ref(null);
		onClickOutside(login, () => {
			loginShow.value = false;
		});
		return {
			loginShow,
			login,
			store,
		};
	},
});
</script>

<style scoped>
.link {
	color: #ffffff;
	padding: 0 20px;
}
.header {
	display: flex;
	align-items: center;
	max-width: 1400px;
	margin: 0 auto;
	padding: 15px 40px;
}
.nav {
	flex: 1;
	margin-left: 40px;
}
.nav .link {
}
.entrance .link {
}
.entrance {
	text-align: right;
}
.login-container {
	position: fixed;
	display: flex;
	justify-content: center;
	left: 0;
	right: 0;
	top: 100px;
	animation: 0.5s linear login-hidden;
}
.entrance {
	color: #ffffff;
}
.entrance span {
	padding-right: 10px;
	padding-left: 10px;
}
.link-button {
	color: rgba(255, 255, 255, 0.6);
}
@media only screen and (max-width: 768px) {
	.header {
		padding: 20px;
	}
}
</style>
