<template>
	<div class="result-container">
		<div class="result">
			<a-result :status="status" :title="title" :sub-title="subTitle">
				<template #extra>
					<template v-if="status === 'success'">
						<a-button
							type="primary"
							size="large"
							@click="$router.push(`/admin/account_info/${accountId}`)"
							>查看账户</a-button
						>
						<a-button size="large" @click="$router.push('/admin/shop')">
							继续开通
						</a-button>
					</template>
					<template v-else>
						<a-button
							size="large"
							type="primary"
							@click="$router.push('/admin/shop')"
						>
							重试开通
						</a-button>
						<a-button size="large">联系客服</a-button>
					</template>
				</template>
			</a-result>
		</div>
	</div>
</template>

<script>
export default {
	name: "ShopResult",
	props: {
		status: String,
		title: String,
		subTitle: String,
		accountId: Number,
	},
};
</script>

<style scoped>
.result-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	z-index: 999;
	background-color: rgba(32, 33, 36, 0.6);
}
.result {
	width: 360px;
	height: 360px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
}
</style>
