<template>
	<module-title back="1" title="编辑账户"
		>用户名: {{ username }} | 节点类型: {{ game }}</module-title
	>
	<div class="operations">
		<a-button
			class="button"
			type="danger"
			v-if="selectedRowKeys.length > 0 && store.state.user.role === 'admin'"
			@click="deleteNodesFromAccount(id, selectedRowKeys)"
			>删除选中的节点</a-button
		>
		<a-button
			class="button"
			type="primary"
			v-if="selectedRowKeys.length > 0"
			@click="previewRenewAccountNodes('part', selectedRowKeys)"
			>续费选中的节点</a-button
		>
		<a-button class="button" @click="getAccountNodesList">
			<ReloadOutlined />重新加载
		</a-button>
		<a-button
			type="primary"
			class="button"
			@click="previewRenewAccountNodes('all', [])"
		>
			<CalendarOutlined />续费所有节点
		</a-button>
		<a-button
			class="button"
			@click="addNodesShow = true"
			v-if="store.state.user.role === 'admin'"
			>添加节点</a-button
		>
		<a-button
			class="button"
			@click="getUserList"
			v-if="store.state.user.role === 'admin'"
			>转移账户</a-button
		>
		<a-button
			class="button"
			@click="editBpsShow = true"
			v-if="store.state.user.role === 'admin'"
			>修改带宽</a-button
		>
		<a-button
			class="button"
			@click="editUpdateMaxCountShow = true"
			v-if="store.state.user.role === 'admin'"
			>修改刷新次数</a-button
		>
	</div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="list"
			:row-key="(record) => record.id"
			:loading="tableLoading"
			:row-selection="{
				selectedRowKeys: selectedRowKeys,
				onChange: onSelectChange,
			}"
			:pagination="store.state.pagination"
			size="small"
		>
			<template #action="{ record }">
				<span v-if="errors.includes(record.id.toString())" style="color: red"
					>未开通成功</span
				>
				<a-button
					@click="previewRenewAccountNodes('part', record.id)"
					style="margin-right: 10px"
					type="link"
					>续费
				</a-button>
				<!--气泡确认-->
				<a-popconfirm
					title="确定删除这个节点么?"
					ok-text="确定"
					cancal-text="取消"
					@confirm="deleteNodesFromAccount(id, record.id)"
					v-if="store.state.user.role === 'admin'"
				>
					<a-button type="link">删除</a-button>
				</a-popconfirm>
			</template>
		</a-table>
	</div>
	<!--修改刷新频率-->
	<popup
		:popup-show="editUpdateMaxCountShow"
		title="修改用户刷新频率"
		@handle-cancel="editUpdateMaxCountShow = false"
		@handle-ok="editUpdateMaxCount"
		@handle-close="editUpdateMaxCountShow = false"
	>
		<a-input-number size="large" v-model:value="updateMaxCount" />
	</popup>
	<!--添加节点窗口-->
	<!--修改节点带宽-->
	<popup
		:popup-show="editBpsShow"
		title="修改账户带宽"
		@handleCancel="editBpsShow = false"
		@handleOk="editBps(id)"
		@handleClose="editBpsShow = false"
	>
		<a-input-number size="large" v-model:value="bps" />KB
	</popup>
	<popup
		:popup-show="renewShow"
		:title="type === 'all' ? '给所有节点续费' : `给节点ID ${nodes} 续费`"
		@handleCancel="renewShow = false"
		@handleOk="
			renewShow = false;
			renewAccountNodes();
		"
		@handleClose="renewShow = false"
	>
		<a-input-number size="large" :min="1" :max="12" v-model:value="month" />
		<span class="tips">请输入要续费的月数，即你要续费几个月</span>
	</popup>
	<!--添加节点窗口-->
	<popup
		:popup-show="addNodesShow"
		title="给账户增加节点"
		@handleCancel="addNodesShow = false"
		@handleOk="addAccountNodes()"
		@handleClose="addNodesShow = false"
	>
		<usable-nodes-by-game-id
			:game_id="game_id"
			:account_id="id"
			ref="usableNodesRef"
		/>
	</popup>
	<!--转移账户窗口-->
	<popup
		:popup-show="changeAccountUserShow"
		title="转移账户到其他用户"
		@handleCancel="changeAccountUserShow = false"
		@handleClose="changeAccountUserShow = false"
		@handleOk="changeAccountUser"
	>
		<div class="dropdown_container">
			<h3>转移到</h3>
			<a-select v-model="selectUsername" size="large" style="width: 200px">
				<a-select-option
					v-for="user in users"
					v-bind:key="user.id"
					@click="selectUser(user.username, user.id)"
					>{{ user.username }}</a-select-option
				>
			</a-select>
		</div>
	</popup>
</template>

<script>
import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons-vue";
import { onMounted, ref } from "vue";
import { account, user } from "@/utils/api";
import Popup from "@/components/Popup";
import { message, Modal } from "ant-design-vue";
import UsableNodesByGameId from "@/components/UsableNodesByGameId";
import { useStore } from "vuex";
import ModuleTitle from "@/components/ModuleTitle";
import { number } from "vue-types";

export default {
	props: { id: String },
	components: {
		ModuleTitle,
		UsableNodesByGameId,
		CalendarOutlined,
		ReloadOutlined,
		Popup,
	},
	name: "AccountNodesList",
	setup(props) {
		const store = useStore();
		const usableNodesRef = ref(null);
		const columns = [
			{ title: "ID", dataIndex: "id" },
			{
				title: "位置",
				dataIndex: "group",
			},
			{
				title: "IP",
				dataIndex: "ip",
			},
			{
				title: "剩余(天数)",
				dataIndex: "remaining",
			},
			{
				title: "到期时间",
				dataIndex: "expire_time",
			},
			{
				title: "创建时间",
				dataIndex: "create_time",
			},
			{
				title: "操作",
				slots: { customRender: "action" },
			},
		];
		const list = ref([]);
		const username = ref("");
		const game = ref("");
		const tableLoading = ref(false);
		const game_id = ref(""); // game_id

		// 用户带宽
		const bps = ref(142);
		const errors = ref([]);

		/**
		 * 获得账户下节点列表
		 * @return {Promise<void>}
		 */
		async function getAccountNodesList() {
			tableLoading.value = true;
			account
				.getAccountNodesList(props.id)
				.then((res) => {
					tableLoading.value = false;
					if (res.data.code === 0) {
						({
							list: list.value,
							name: username.value,
							game: game.value,
						} = res.data.data);
						game_id.value = res.data.data.game_id;
						bps.value = res.data.data.bps; // 获取账户带宽
						errors.value = res.data.data.errors;
						updateMaxCount.value = res.data.data.updateMaxCount;
					}
				})
				.catch((err) => {
					tableLoading.value = false;
				});
		}
		const selectedRowKeys = ref([]);
		function onSelectChange(keys) {
			selectedRowKeys.value = keys;
		}

		const renewShow = ref(false);
		const month = ref(1);
		const type = ref("part");
		const nodes = ref("");
		function previewRenewAccountNodes(action, previewNodes) {
			type.value = action;
			nodes.value = previewNodes.toString();
			renewShow.value = true;
		}

		// 计算消耗鲸币
		function calCoin() {
			if (nodes.value === "") {
				return list.value.length * 15 * month.value;
			} else {
				return nodes.value.split(",").length * 15 * month.value;
			}
		}
		// 提交续费申请
		async function submitRenewAccountNodes() {
			await account
				.renewAccountNode(props.id, month.value, type.value, nodes.value)
				.then((res) => {
					if (res.data.code === 0) {
						message.success(res.data.message);
						renewShow.value = false;
						store.commit("updateCoin", res.data.data.coin);
						getAccountNodesList().then();
					} else {
						message.error(res.data.message);
					}
				})
				.catch(() => {
					renewShow.value = false;
				});
		}

		/**
		 * 续费节点
		 * @return {Promise<void>}
		 */
		async function renewAccountNodes() {
			Modal.confirm({
				title: `续费账户${username.value}`,
				content: `包含${
					nodes.value === "" ? list.value.length : nodes.value.split(",").length
				}个节点,续费${month.value}个月, 消费${calCoin()}个鲸币`,
				centered: true,
				okText: "确认续费",
				cancelText: "取消",
				onOk() {
					submitRenewAccountNodes().then();
				},
			});
		}

		/**
		 * 修改节点带宽
		 */
		const editBpsShow = ref(false);
		async function editBps() {
			await account.editAccountLimit(props.id, bps.value).then((res) => {
				if (res.data.code === 0) {
					message.success(res.data.message);
				} else {
					message.error(res.data.message);
				}
			});
			editBpsShow.value = false;
		}

		/**
		 * 删除节点
		 */
		async function deleteNodesFromAccount(account_id, nodes) {
			await account
				.deleteNodesFromAccount(account_id, nodes.toString())
				.then((res) => {
					if (res.data.code === 0) {
						message.success(res.data.message);
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => message.error(err));
			getAccountNodesList().then();
		}

		/**
		 * 添加节点
		 */
		const addNodesShow = ref(false);
		function addAccountNodes() {
			addNodesShow.value = false;
			usableNodesRef.value.addNodesIntoAccount();
		}
		onMounted(() => {
			getAccountNodesList().then();
			// getAccountFrequency();
		});

		/**
		 * 转移账户
		 */
		const changeAccountUserShow = ref(false);
		const users = ref([]);
		const userId = ref(null);
		const selectUsername = ref("选择你要转移的用户");
		async function getUserList() {
			await user.list().then((res) => {
				if (res.data.code === 0) {
					users.value = res.data.data;
					console.log(users.value);
					changeAccountUserShow.value = true;
				} else {
					message.error(res.data.message);
				}
			});
		}
		// 选择要转移的账户
		function selectUser(username, id) {
			userId.value = id;
			selectUsername.value = username;
		}
		async function changeAccountUser() {
			changeAccountUserShow.value = false;
			await account.changeAccountUser(props.id, userId.value).then((res) => {
				if (res.data.code === 0) {
					message.success(res.data.message);
				} else {
					message.error(res.data.message);
				}
			});
		}

		// 修改用户可刷新节点的频率
		const editUpdateMaxCountShow = ref(false);
		const updateMaxCount = ref(0);

		async function getAccountFrequency() {
			await account.getAccountFrequency(props.id).then((res) => {
				updateMaxCount.value = res.data;
			});
		}
		async function editUpdateMaxCount() {
			await account
				.editAccountMaxUpdateTimes(props.id, updateMaxCount.value)
				.then(() => {
					message.success("修改成功");
				});
			editUpdateMaxCountShow.value = false;
		}
		return {
			store, // store变量
			list,
			username,
			game,
			columns,
			getAccountNodesList,
			changeAccountUserShow, // 转移账户窗口
			getUserList, // 获得用户列表
			users,
			selectUsername,
			selectUser,
			changeAccountUser,
			tableLoading,
			selectedRowKeys,
			onSelectChange,
			renewShow,
			previewRenewAccountNodes,
			renewAccountNodes,
			month,
			nodes,
			type,
			deleteNodesFromAccount,
			game_id,
			addNodesShow, // 添加节点窗口开关
			usableNodesRef, // 子组件可用节点的ref
			addAccountNodes, // 添加节点
			editBps,
			editBpsShow,
			editUpdateMaxCountShow,
			editUpdateMaxCount,
			updateMaxCount,
			bps,
			errors,
		};
	},
};
</script>

<style scoped>
.dropdown_container {
	display: flex;
	align-items: flex-end;
}

.dropdown_container h3 {
	margin-right: 20px;
}
</style>
