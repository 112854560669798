<template>
	<module-title title="账户详情" back="1">
		<b>账户名: {{ username }} 到期时间: {{ expireTime }}</b>
	</module-title>
	<div class="modules">
		<div class="module">
			<h1>HTTP</h1>
			<a-textarea
				:value="httpExport"
				size="small"
				:rows="8"
				id="http"
			></a-textarea>
			<a-button @click="copyTxt('http')" block size="large" class="down-link"
				>复制http列表到剪贴板</a-button
			>
			<a-button
				block
				size="large"
				type="primary"
				@click="fileSave('http')"
				class="down-link"
				>下载http列表</a-button
			>
		</div>
		<div class="module">
			<h1>SK5</h1>
			<a-textarea
				:value="sk5Export"
				size="small"
				:rows="8"
				id="sk5"
			></a-textarea>
			<a-button @click="copyTxt('sk5')" block size="large" class="down-link"
				>复制sk5列表到剪贴板</a-button
			>
			<a-button
				block
				size="large"
				class="down-link"
				type="primary"
				@click="fileSave('sk5')"
				>下载sk5列表</a-button
			>
		</div>
		<div class="module">
			<h1>SK5万安格式</h1>
			<a-textarea
				:value="sk5WananExport"
				size="small"
				:rows="8"
				id="sk5Wanan"
			></a-textarea>
			<a-button
				@click="copyTxt('sk5Wanan')"
				block
				size="large"
				class="down-link"
				>复制sk5万安格式列表到剪贴板</a-button
			>
			<a-button
				type="primary"
				block
				size="large"
				class="down-link"
				@click="fileSave('sk5Wanan')"
				>下载sk5万安格式列表</a-button
			>
		</div>
	</div>
</template>

<script>
import { account } from "@/utils/api";
import { onMounted, ref } from "vue";
import ModuleTitle from "@/components/ModuleTitle";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileSaver = require("file-saver");

export default {
	components: { ModuleTitle },
	props: ["id"],
	name: "AdminAccountInfo",
	setup(props) {
		const username = ref("");
		const expireTime = ref("");
		const httpList = ref([]);
		const sk5List = ref([]);
		const httpDown = ref("");
		const sk5Down = ref("");
		const httpExport = ref("");
		const sk5Export = ref("");
		const sk5WananList = ref([]);
		const sk5WananDown = ref("");
		const sk5WananExport = ref("");
		async function getAccountInfo() {
			account.getAccountExport(props.id).then(({ data }) => {
				const accountInfo = data;
				username.value = accountInfo.username;
				expireTime.value = accountInfo.expire_time;
				httpList.value = accountInfo.http.list;
				sk5List.value = accountInfo.sk5.list;
				httpDown.value = accountInfo.http.downloadPath;
				sk5Down.value = accountInfo.sk5.downloadPath;
				httpExport.value = accountInfo.http.content;
				sk5Export.value = accountInfo.sk5.content;
				sk5WananList.value = accountInfo.sk5_wanan.list;
				sk5WananExport.value = accountInfo.sk5_wanan.content;
				sk5WananDown.value = accountInfo.sk5_wanan.downloadPath;
			});
		}
		// 保存文件
		function fileSave(type) {
			switch (type) {
				case "http":
					FileSaver.saveAs(
						new Blob([httpExport.value], {
							type: "text/plain;charset=utf-8",
						}),
						username.value + "_http.txt"
					);
					break;
				case "sk5":
					FileSaver.saveAs(
						new Blob([sk5Export.value], {
							type: "text/plain;charset=utf-8",
						}),
						username.value + "_sk5.txt"
					);
					break;
				case "sk5Wanan":
					FileSaver.saveAs(
						new Blob([sk5WananExport.value], {
							type: "text/plain;charset=utf-8",
						}),
						username.value + "_sk5_wanan.txt"
					);
					break;
				default:
					break;
			}
		}
		// 复制
		function copyTxt(type) {
			switch (type) {
				case "http": {
					const copyList = document.getElementById("http");
					copyList.select();
					if (document.execCommand("copy")) {
						document.execCommand("copy");
					}
					break;
				}
				case "sk5": {
					const copyList = document.getElementById("sk5");
					copyList.select();
					if (document.execCommand("copy")) {
						document.execCommand("copy");
					}
					break;
				}
				case "sk5Wanan": {
					const copyList = document.getElementById("sk5Wanan");
					copyList.select();
					if (document.execCommand("copy")) {
						document.execCommand("copy");
					}
					break;
				}
				default:
					break;
			}
		}
		onMounted(() => {
			getAccountInfo().then();
		});
		return {
			username,
			httpList,
			sk5List,
			httpDown,
			sk5Down,
			expireTime,
			httpExport,
			sk5Export,
			sk5WananList,
			sk5WananDown,
			sk5WananExport,
			fileSave, // 保存文件
			copyTxt,
		};
	},
};
</script>

<style scoped>
.modules {
	display: flex;
	padding: 20px;
	flex-wrap: wrap;
}
.module {
	margin-right: 40px;
	width: 400px;
	margin-bottom: 40px;
	background: #ffffff;
	padding: 20px;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
}
.module-list {
	list-style: none;
	margin: 0;
	padding: 20px 0;
	background: #e1e4e8;
	color: #586069;
	border-radius: 8px;
}
.module-list li {
	font-size: 16px;
	padding: 10px 20px;
}
.down-link {
	margin-top: 10px;
}
@media only screen and (max-width: 768px) {
	.module {
		width: 100%;
		margin-right: 0;
	}
}
</style>
