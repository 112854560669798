<template>
	<header class="header">
		<module-title title="节点购买">
			<span> 准确的选择业务类型和节点地区可以更好的帮助您的业务 </span>
		</module-title>
	</header>
	<div class="shop">
		<shop-steps :step="$route.meta.step"></shop-steps>
		<section class="shop-content">
			<router-view></router-view>
		</section>
	</div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle";
import ShopSteps from "@/components/ShopSteps";
import { ref } from "vue";
export default {
	name: "Shop",
	components: { ModuleTitle, ShopSteps },
	setup() {
		const step = ref("1");
		return {
			step,
		};
	},
};
</script>

<style scoped>
.shop {
	padding-bottom: 100px;
}
</style>
