/**
 * axios封装
 * */

import axios from "axios";
import { message } from "ant-design-vue";
import store from "../store";
import { base } from "./axiosConfig";
/**
 * 读取当前网站的语言环境
 * */

/**
 * 请求失败后的处理
 * @param {number} status 请求失败的状态码
 * */

/**
 * 请求成功后的处理
 * */

/**
 * 创建axios,并设置配置
 * */

const serviceJson = axios.create({
	timeout: 600000, // 请求超时时间
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": true,
	},
	baseURL: base.url,
});

/**
 * 请求添加token
 */
serviceJson.interceptors.request.use((config) => {
	if (store.state.token) {
		config.headers.Authorization = `Bearer ${store.state.token}`;
		return config;
	}
	return config;
});
/**
 * 响应处理
 * */
serviceJson.interceptors.response.use(
	(res) => {
		return Promise.resolve(res);
	},
	(err) => {
		message.error(err.response.data.statusCode + err.response.data.message);
		return Promise.reject(err);
	}
);

export default serviceJson;
