<template>
	<module-title :title="nodeInfo.group + ' | ' + nodeInfo.ip" back="1" />

	<div class="operation">
		<a-button class="operation-button">
			<ReloadOutlined />
		</a-button>
		<a-button
			type="primary"
			class="operation-button"
			@click="createNodeUserShow = true"
		>
			<PlusOutlined />创建用户
		</a-button>
	</div>
	<div class="operation"></div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="users"
			:row-key="(record) => record.name"
			size="middle"
			:pagination="store.state.pagination"
		>
			<template #action="{ record }">
				<a-popconfirm
					title="你确定要从节点上删除此用户么"
					ok-text="确定"
					cancel-text="取消"
					@confirm="delNodeUser(record.name)"
				>
					<a-button type="link">删除用户</a-button>
				</a-popconfirm>
			</template>
		</a-table>
	</div>
	<!--修改节点-->
	<!--弹出创建用户-->
	<popup
		:popup-show="createNodeUserShow"
		:title="nodeInfo.group + nodeInfo.ip + '上创建用户'"
		@handleCancel="createNodeUserShow = false"
		@handleOk="createNodeUser"
		@handleClose="createNodeUserShow = false"
	>
		<a-input
			size="large"
			v-model:value="createUserInfo.name"
			placeholder="请输入用户名"
		/>
		<div class="tips">
			未做任何校验，仅供管理员测试或临时管理使用，请自行判断用户是否重复。
		</div>
		<a-input
			size="large"
			v-model:value="createUserInfo.password"
			placeholder="请输入密码"
		/>
	</popup>
</template>

<script>
import { message } from "ant-design-vue";
import { node } from "@/utils/api";
import { onMounted, ref } from "vue";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons-vue";
import Popup from "@/components/Popup";
import ModuleTitle from "@/components/ModuleTitle";
import { useStore } from "vuex";

export default {
	props: ["id"],
	name: "Node",
	components: { ModuleTitle, Popup, ReloadOutlined, PlusOutlined },
	setup(props) {
		const store = useStore();
		const users = ref([]);
		const nodeInfo = ref({});
		const createUserInfo = ref({
			name: "",
			password: "",
		});
		const createNodeUserShow = ref(false);
		const columns = [
			{
				title: "用户名",
				dataIndex: "name",
			},
			{
				title: "密码",
				dataIndex: "password",
			},
			{
				title: "带宽限制",
				dataIndex: "up",
			},
			{
				title: "操作",
				slots: { customRender: "action" },
			},
		];
		// 查看节点
		async function getNode(id) {
			users.value = [];
			node
				.getNode(id)
				.then((res) => {
					if (res.data.code === 0) {
						nodeInfo.value = res.data.data.info;
						message.success(res.data.message);
						const userObj = res.data.data.user_list;
						for (const key in userObj) {
							users.value.push({
								name: userObj[key][0],
								password: userObj[key][1],
								up: userObj[key][2],
								down: userObj[key][3],
							});
						}
					} else {
						message.warn(res.data.message);
					}
				})
				.catch((err) => console.log(err));
		}
		// 添加用户
		async function createNodeUser() {
			node
				.createNodeUser(
					props.id,
					createUserInfo.value.name,
					createUserInfo.value.password
				)
				.then((res) => {
					if (res.data.code === 0) {
						// console.log(res.data);
						message.success(res.data.message);
						createNodeUserShow.value = false;
						setTimeout(() => {
							getNode(props.id).then();
						}, 2000);
					} else {
						message.error(res.data.message);
					}
				});
		}
		// 删除用户
		async function delNodeUser(username) {
			node
				.delNodeUser(props.id, username)
				.then((res) => {
					if (res.data.code === 0) {
						// console.log(res.data);
						message.success(res.data.message);
						createNodeUserShow.value = false;
						setTimeout(() => {
							getNode(props.id).then();
						}, 2000);
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => console.log(err));
		}
		onMounted(() => {
			getNode(props.id).then();
		});
		return {
			users,
			columns,
			nodeInfo,
			createUserInfo,
			createNodeUser,
			createNodeUserShow,
			delNodeUser,
			store,
		};
	},
};
</script>

<style scoped>
.tips {
	font-size: 12px;
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>
