<template>
	<module-title title="节点类型(游戏)">
		节点类型可以在每个节点上使用一次，新增加的节点类型对应的节点将是全新的
	</module-title>
	<div class="operation">
		<a-button class="operation-button">
			<ReloadOutlined />
		</a-button>
		<a-button
			type="primary"
			class="operation-button"
			@click="createGameShow = true"
		>
			<PlusOutlined />
			创建新类型
		</a-button>
		<a-input-search
			style="width: 200px"
			v-model:value="searchKeyword"
			allowClear
			@search="search"
		></a-input-search>
	</div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="games"
			:row-key="(record) => record.id"
			size="middle"
			:pagination="store.state.pagination"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'operations'">
					<a-button @click="popEditGame(record.id, record.name)" type="primary"
						>修改</a-button
					>
				</template>
			</template>
		</a-table>
	</div>
	<popup
		:popup-show="createGameShow"
		title="创建新的节点类型"
		@handleCancel="createGameShow = false"
		@handleOk="createGame(gameName)"
		@handleClose="createGameShow = false"
	>
		<a-input
			size="large"
			v-model:value="gameName"
			placeholder="请填写节点类型名称"
		/>
		<span class="tips">
			节点类型可以在每个节点上使用一次，新增加的节点类型对应的节点将是全新的
		</span>
	</popup>
	<popup
		:popup-show="editGameShow"
		:title="'编辑' + editGameNameOver"
		@handleCancel="editGameShow = false"
		@handleOk="editGame"
		@handleClose="editGameShow = false"
	>
		<h2>{{}}</h2>
		<a-input
			size="large"
			v-model:value="editGameName"
			placeholder="请填写新的节点类型名称"
		/>
		<span class="tips"> 所有节点名称将会更改为新的节点类型名称 </span>
	</popup>
</template>

<script>
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons-vue";
import { onMounted, ref } from "vue";
import { game } from "@/utils/api";
import { message } from "ant-design-vue";
import Popup from "@/components/Popup";
import ModuleTitle from "@/components/ModuleTitle";
import { useStore } from "vuex";
export default {
	name: "Games",
	components: {
		ModuleTitle,
		Popup,
		PlusOutlined,
		ReloadOutlined,
	},
	setup() {
		const store = useStore();
		const columns = [
			{
				title: "ID",
				dataIndex: "id",
			},
			{
				title: "名称",
				dataIndex: "name",
			},
			{
				title: "首字母",
				dataIndex: "initial",
			},
			{
				title: "操作",
				key: "operations",
			},
		];
		const games = ref([]);
		const allGames = ref([]);

		// 请求所有节点类型
		async function getGameList(initial) {
			const data = await game.getGameList(initial).then((res) => res.data);
			if (data.code === 0) {
				allGames.value = data.data;
				games.value = data.data;
			} else {
				message.error(data.message);
			}
		}

		// 新增节点类型
		const createGameShow = ref(false);
		const gameName = ref("");
		async function createGame(name) {
			const data = await game.createGame(name).then((res) => res.data);
			if (data.code === 0) {
				message.success(data.message);
				createGameShow.value = false;
			}
		}

		// 搜索节点类型
		const searchKeyword = ref("");
		function search(searchValue) {
			if (searchValue === "") {
				games.value = allGames.value;
			} else {
				games.value = allGames.value.filter((item) => {
					return item.name.includes(searchValue);
				});
			}
		}

		// TODO 修改节点类型
		const editGameShow = ref(false);
		const editGameNameOver = ref("");
		const editGameId = ref("");
		const editGameName = ref("");
		function popEditGame(id, name) {
			editGameId.value = id;
			editGameNameOver.value = name;
			editGameShow.value = true;
		}
		async function editGame() {
			game.editGame(editGameId.value, editGameName.value).then(() => {
				editGameShow.value = false;
				message.success("修改成功");
				getGameList().then();
			});
		}
		onMounted(async () => {
			await getGameList();
		});

		return {
			store,
			columns,
			getGameList,
			games,
			createGameShow,
			createGame,
			gameName,
			searchKeyword,
			search,
			editGameShow,
			editGame,
			editGameId,
			editGameName,
			editGameNameOver,
			popEditGame,
		};
	},
};
</script>

<style scoped>
.action-button {
	margin-right: 10px;
}
.tips {
	display: block;
	margin-top: 10px;
	font-size: 12px;
}
</style>
