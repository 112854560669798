<template>
	<div class="module">
		<div class="module-title">
			<h3>配置您要采购的节点</h3>
			<span>绿色数字为当前位置可用的节点数量</span>
		</div>
		<div class="groups">
			<div v-for="(node, index) in groups" :key="index" class="group-module">
				<div class="title">
					<h4>
						{{ node.groupName }}
						<b class="green">{{ node.canUseNodesCount }}</b>
					</h4>
				</div>
				<div class="number">
					<a-input-number
						size="large"
						:min="0"
						:max="node.canUseNodesCount"
						v-model:value="node.selectNodesCount"
						@change="countSelectNodes"
					></a-input-number>
				</div>
			</div>
		</div>
		<div class="module-title">
			<h3>填写用户名密码</h3>
			<span>订购成功后，可使用用户密码登录代理，请妥善保存。</span>
		</div>
		<div class="user">
			<div class="user-content">
				<label style="position: relative">
					<a-input
						size="large"
						placeholder="请输入您的用户名, 4-12位字符"
						class="user-input"
						v-model:value="accountInput.username"
						@change="checkUsername"
					/>
					<label v-show="checkUsernameResult.check" class="check-result">
						<b v-if="checkUsernameResult.result" style="color: #2ea44f"> OK </b>
						<span v-else style="color: darkgoldenrod">
							{{ checkUsernameResult.message }}
						</span>
					</label>
				</label>
				<label style="position: relative">
					<a-input
						size="large"
						placeholder="请输入您的密码, 4-12位字符"
						class="user-input"
						v-model:value="accountInput.password"
						@change="checkPassword"
					/>
					<label v-show="checkPasswordResult.check" class="check-result">
						<span v-if="checkPasswordResult.result" style="color: #2ea44f">
							OK
						</span>
						<span v-else style="color: darkgoldenrod">
							{{ checkPasswordResult.message }}
						</span>
					</label>
				</label>
			</div>
		</div>
	</div>
	<shop-submit
		:game="game"
		:count="selectNodesCount"
		v-on:buy="createOrders"
	></shop-submit>
	<loading tip="账户开通中,请稍候..." v-if="loadingShow"></loading>
	<shop-result
		v-if="resultShow"
		:status="resultOption.status"
		:sub-title="resultOption.subTitle"
		:account-id="resultOption.accountId"
		:title="resultOption.title"
	/>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { account } from "@/utils/api";
import { message, Modal } from "ant-design-vue";
import { useStore } from "vuex";
import ShopSubmit from "@/components/ShopSubmit";
import Loading from "@/components/Loading";
import ShopResult from "@/components/ShopResult";

export default {
	name: "ShopOrder",
	props: { id: String, game: String },
	components: { ShopResult, Loading, ShopSubmit },
	setup(props) {
		const store = useStore();
		const groups = ref([]);
		async function getCanUseNodes(id) {
			await account
				.getCanUseNodesByGameId(id)
				.then((res) => {
					if (res.data.code === 0) {
						for (const group of res.data.data) {
							groups.value.push({
								groupId: group.group_id,
								groupName: group.group_name,
								canUseNodesCount: group.can_use_nodes_count,
								selectNodesCount: 0,
							});
						}
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => message.error(err));
		}
		// 获取已选择节点数量
		const selectNodesCount = ref(0);
		function countSelectNodes() {
			selectNodesCount.value = 0;
			for (const node of groups.value) {
				selectNodesCount.value = selectNodesCount.value + node.selectNodesCount;
			}
		}
		// 处理输入的用户名密码
		const accountInput = reactive({
			username: "",
			password: "",
		});

		// 检索用户名是否重复
		const windowTimeOut = ref(1);
		const checkUsernameResult = reactive({
			result: false,
			check: false,
			message: "用户名应为4到10位的英文或数字",
		});
		async function checkUsername() {
			if (accountInput.username === "") {
				checkUsernameResult.check = false;
			} else {
				if (!/^[a-zA-Z0-9]{4,10}$/.test(accountInput.username)) {
					checkUsernameResult.check = true;
					checkUsernameResult.result = false;
					checkUsernameResult.message = "用户名应为4到10位的英文或数字";
				} else {
					window.clearTimeout(windowTimeOut.value);
					windowTimeOut.value = window.setTimeout(() => {
						account.checkUsername(accountInput.username).then((res) => {
							if (res.data.code === 0) {
								checkUsernameResult.check = true;
								checkUsernameResult.result = true;
								checkUsernameResult.message = "用户名可以使用";
							} else {
								checkUsernameResult.check = true;
								checkUsernameResult.result = false;
								checkUsernameResult.message = "用户名重复或禁止使用";
							}
						});
					}, 500);
				}
			}
		}
		// 检查密码
		const checkPasswordResult = reactive({
			result: false,
			check: false,
			message: "密码应为4到10位的英文或数字",
		});
		function checkPassword() {
			if (accountInput.password === "") {
				checkPasswordResult.check = false;
			} else {
				if (!/^[a-zA-Z0-9]{4,10}$/.test(accountInput.password)) {
					checkPasswordResult.check = true;
					checkPasswordResult.result = false;
					checkPasswordResult.message = "密码应为4到10位的英文或数字";
				} else {
					checkPasswordResult.check = true;
					checkPasswordResult.result = true;
					checkPasswordResult.message = "可以使用";
				}
			}
		}
		// 创建账户
		const loadingShow = ref(false);
		const resultShow = ref(false);
		const resultOption = reactive({
			title: "",
			subTitle: "",
			status: "",
			accountId: "",
		});
		// 提交订单并展示结果
		async function submitOrders(orders) {
			loadingShow.value = true;
			await account
				.createAccount({
					username: accountInput.username,
					password: accountInput.password,
					game_id: props.id,
					orders: orders,
					user_id: store.state.user.id,
					nodes_count: selectNodesCount.value,
				})
				.then((res) => {
					loadingShow.value = false;
					if (res.data.code === 0) {
						resultOption.title = `账户${res.data.data.username}开通成功`;
						resultOption.subTitle = `包含${res.data.data.nodes.length}个节点`;
						resultOption.accountId = res.data.data.id;
						resultOption.status = "success";
						console.log(res.data.data.coin);
						store.commit("updateCoin", res.data.data.coin);
						resultShow.value = true;
					} else {
						message.error(res.data.message);
					}
				})
				.catch(() => (loadingShow.value = false));
		}
		// 检查订单及用户名密码合法性
		function checkOrders(orders) {
			if (orders.length === 0) {
				message.error("请配置您要采购的节点");
				return false;
			}
			if (!(checkUsernameResult.check && checkUsernameResult.result)) {
				message.error(checkUsernameResult.message);
				return false;
			}
			if (!(checkPasswordResult.check && checkPasswordResult.result)) {
				message.error(checkPasswordResult.message);
				return false;
			}
			return true;
		}
		// 获得订单
		function getOrders() {
			const orders = [];
			for (const group of groups.value) {
				if (group?.selectNodesCount > 0) {
					orders.push({
						group_id: group?.groupId,
						node_count: group?.selectNodesCount,
					});
				}
			}
			return orders;
		}
		// 创建订单
		async function createOrders() {
			const orders = getOrders();
			if (checkOrders(orders)) {
				Modal.confirm({
					title: `提交开通账户${accountInput.username}`,
					content: `包含${selectNodesCount.value}个节点,消费${
						selectNodesCount.value * 15
					}个鲸币`,
					centered: true,
					okText: "确认开通",
					cancelText: "取消开通",
					onOk() {
						submitOrders(orders).then();
					},
					onCancel() {
						message.info("取消提交");
					},
				});
			}
		}

		onMounted(() => {
			getCanUseNodes(props.id).then();
		});
		return {
			groups,
			selectNodesCount,
			countSelectNodes,
			accountInput,
			createOrders,
			loadingShow,
			resultShow,
			resultOption,
			checkUsername,
			checkPassword,
			checkPasswordResult,
			checkUsernameResult,
		};
	},
};
</script>

<style scoped>
.module {
	background: #ffffff;
	margin: 70px 20px 20px 20px;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
}
.groups {
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
	align-content: space-between;
}
.group-module {
	display: flex;
	align-items: baseline;
	border-bottom: 1px #e4ebf1 solid;
	flex-wrap: wrap;
	margin: 4px 10px;
	padding: 0 0 4px 0;
	min-width: 30%;
}
.group-module .title {
	display: flex;
	font-size: 14px;
	align-items: baseline;
	flex: 1;
}
.group-module .title b {
	font-size: 18px;
	font-weight: 500;
	margin-left: 10px;
}
.group-module .title h4 {
	color: #24292e;
}
.group-module .number span {
	font-size: 12px;
	color: #586069;
}
.green {
	color: #2ea44f;
}
.user-content {
	display: flex;
	flex-wrap: wrap;
}
.user-content .user-input {
	margin-right: 20px;
	width: 300px;
}
.user {
	padding: 20px;
	background: #ffffff;
	width: 100%;
}
.module-title {
	padding: 20px;
	background: rgb(250, 250, 250);
}
.module-title h3 {
	color: #24292e;
}
.module-title p {
	color: #e1e4e8;
}
.check-result {
	position: absolute;
	right: 25px;
	top: 10px;
	font-size: 12px;
}
</style>
