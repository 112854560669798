<template>
	<div class="loading-container">
		<div class="loading">
			<a-spin size="large" :indicator="indicator" />
			<h4>{{ tip }}</h4>
		</div>
	</div>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { h } from "vue";
export default {
	name: "Loading",
	props: { tip: String },
	setup() {
		const indicator = h(LoadingOutlined, {
			style: {
				fontSize: "36px",
				color: "#ffffff",
			},
			spin: true,
		});
		return {
			indicator,
		};
	},
};
</script>

<style>
.loading-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	z-index: 999;
	background-color: rgba(32, 33, 36, 0.6);
}
.loading {
	width: 360px;
	height: 360px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.loading h4 {
	padding-top: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
}
</style>
