<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: "Log",
};
</script>

<style scoped></style>
