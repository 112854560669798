<template>
	<module-title :title="group + zid + '-' + host" back="1"></module-title>
	<div class="overview">
		<span>
			<b class="green"
				>{{ searchResult.time.start }} 到 {{ searchResult.time.end }}
			</b></span
		>
		<span class="dec">
			<b> {{ searchResult.total }} 个结果</b>
		</span>
		<span class="dec">
			搜索: <b>{{ searchResult.requestCostTime }}</b>
		</span>
		<span class="dec">
			计算: <b>{{ searchResult.countCostTime }}</b>
		</span>
	</div>
	<div
		v-for="user in searchResult.hitsByUser"
		v-bind:key="user.username"
		class="module"
	>
		<div class="user-overview">
			<h2>{{ user.username }}</h2>
			<span
				>连接数: <b>{{ user.conn }}</b></span
			>
			<span
				>入站: <b>{{ user.in }}</b></span
			>
			<span
				>出站: <b>{{ user.out }}</b></span
			>
			<span
				>连接时间: <b>{{ user.time }}</b></span
			>
			<a-button type="link" @click="openRemotes(user.username, user.remotes)">
				访问地址
			</a-button>
		</div>
		<div class="list">
			<a-table
				:columns="columns"
				:data-source="user.requests"
				:row-key="(record) => record.ip"
				size="small"
			></a-table>
		</div>
	</div>
	<a-drawer
		:title="drawerTitle"
		placement="right"
		:closable="false"
		v-model:visible="drawerOpen"
	>
		<ul class="remote-list">
			<li v-for="remote in remoteList" :key="remote.index">
				{{ remote }}
			</li>
		</ul>
	</a-drawer>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
import { onMounted, reactive, ref } from "vue";
import { log } from "@/utils/api";
export default {
	props: {
		host: String,
		group: String,
		zid: String,
		start: String,
		end: String,
	},
	name: "LogByHostAndZid",
	components: { ModuleTitle },
	setup(props) {
		const columns = [
			{
				title: "请求IP",
				dataIndex: "ip",
			},
			{
				title: "连接数",
				dataIndex: "conn",
			},
			{
				title: "入站流量(统计)",
				dataIndex: "in",
			},
			{
				title: "出站流量(统计)",
				dataIndex: "out",
			},
		];
		const searchResult = reactive({
			time: {
				start: "",
				end: "",
			},
			total: 0,
			requestCostTime: "",
			countCostTime: "",
			hitsByUser: [],
		});
		const searchLogLoading = ref(false);
		async function searchLogByHostAndZid() {
			searchLogLoading.value = true;
			await log
				.searchLogByHostAndZid(props.host, props.zid, props.start, props.end)
				.then((res) => {
					if (res.data.code === 0) {
						searchResult.time.start = res.data.data.time.start;
						searchResult.time.end = res.data.data.time.end;
						searchResult.total = res.data.data.total;
						searchResult.requestCostTime = res.data.data.requestCostTime;
						searchResult.countCostTime = res.data.data.countCostTime;
						searchResult.hitsByUser = res.data.data.hitsByUser;
					}
					console.log(searchResult.hitsByUser);
				});
			searchLogLoading.value = false;
		}
		const drawerTitle = ref("");
		const drawerOpen = ref(false);
		const remoteList = ref([]);
		function openRemotes(title, remotes) {
			console.log(remotes);
			drawerTitle.value = title;
			remoteList.value = remotes;
			drawerOpen.value = true;
		}
		onMounted(() => {
			searchLogByHostAndZid().then();
		});
		return {
			searchResult,
			columns,
			drawerTitle,
			openRemotes,
			remoteList,
			drawerOpen,
		};
	},
};
</script>

<style scoped>
.overview {
	color: #586069;
	margin: 20px;
}
.overview b {
	color: #586069;
	margin-left: 5px;
}
.overview span {
	margin-right: 20px;
}
.picker {
	margin-right: 10px;
}
.overview .green {
	color: #2ea44f;
}
.user-overview {
	margin: 20px;
	padding-top: 20px;
	border-top: 1px #e1e4e8 dashed;
}
.user-overview h2 {
	color: #2ea44f;
}
.remote-list {
	list-style: none;
	font-size: 14px;
	margin: 0;
	padding: 0;
}
.remote-list li {
	border-bottom: 1px #e1e4e8 dashed;
	margin: 10px 0;
	padding: 10px 0;
}
</style>
