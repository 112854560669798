<template>
	<the-admin-header />
	<div class="admin">
		<the-sidebar />
		<div class="view">
			<div class="router-view">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import TheSidebar from "@/components/TheSidebar";
import TheAdminHeader from "@/components/TheAdminHeader";
export default {
	name: "Admin",
	components: { TheAdminHeader, TheSidebar },
};
</script>

<style>
.admin {
	display: flex;
	flex: 1;
}
.view {
	flex: 1;
	overflow: hidden;
	position: relative;
	background: #f5f7fa;
	padding-top: 50px;
	margin-left: 220px;
}
.router-view {
	overflow: auto;
	padding-top: 50px;
}
.view-module {
	background: #ffffff;
	border-radius: 4px;
}
.info {
	background: #e4ebf1;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 16px 20px;
	display: flex;
	align-items: flex-end;
}
.info b {
	font-size: 18px;
	padding-right: 10px;
}
.operation {
	margin: 20px 20px 0 20px;
}
.operation .operation-button {
	margin-right: 10px;
}
.action-link a {
	color: #0366d6;
	text-decoration: underline;
	margin-left: 10px;
}
.content {
	padding: 20px;
}
.operations {
	margin-top: 20px;
	padding: 0 20px;
	display: flex;
	flex-wrap: wrap;
}
.operations .button {
	margin-right: 10px;
}
.tips {
	padding: 0 20px;
	margin-top: 16px;
}
.list {
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
	margin: 10px 20px;
}
@media only screen and (max-width: 768px) {
	.view {
		margin-left: 60px;
	}
}
</style>
