<template>
	<div class="submit">
		<div class="details">
			<div class="order">
				<div class="line">
					<span class="title">已选节点类型:</span>
					<b class="active">{{ game }}</b>
				</div>
				<div class="line">
					<span class="title">数量:</span>
					<b class="active">{{ count }}</b>
				</div>
			</div>
			<div class="charge">
				<div class="number">
					<span class="title">合计费用: </span>
					<b class="money">{{ count * 15 }}.0</b>鲸币
				</div>
			</div>
		</div>

		<div class="actions">
			<div class="z-step">
				<a-button
					class="action-button"
					@click="$router.push('/admin/shop')"
					size="large"
					>上一步</a-button
				>
				<a-button
					class="action-button"
					type="primary"
					size="large"
					@click="$emit('buy')"
				>
					立即购买
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ShopSubmit",
	props: {
		game: String,
		count: Number,
	},
};
</script>

<style scoped>
.submit {
	display: flex;
	height: 80px;
	background: #ffffff;
	width: 100%;
	justify-content: flex-start;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-top: 1px #e1e4e8 solid;
}
.order {
	margin: 10px 20px;
	padding-right: 20px;
	line-height: 30px;
	color: #586069;
	border-right: 1px #e1e4e8 solid;
}
.order .line {
	display: flex;
}
.order .line .title {
	display: block;
	width: 100px;
	text-align: right;
	margin-right: 10px;
}
.details {
	display: flex;
	flex: 1;
}
.charge {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.charge .number {
	margin-bottom: 13px;
}
.charge .number .title {
	color: #586069;
}
.charge .number .money {
	font-size: 18px;
	color: #2ea44f;
}
.active {
	font-size: 16px;
	color: #2ea44f;
}
.actions {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 13px;
}
.actions .action-button {
	margin-right: 20px;
}
.green {
	font-size: 18px;
	font-weight: 600;
	color: #2ea44f;
}
.module {
	width: 30%;
	margin: 0 10px 0 0;
	border-right: 1px #f2f2f2 solid;
	color: #586069;
}
.game {
	margin-left: 10px;
}
.cash {
	border-right: none;
	padding-right: 20px;
	text-align: right;
}
</style>
