<template>
	<header class="header">
		<div class="logo">
			<img
				src="@/assets/img/logo-side.png"
				alt="鲸云"
				height="30"
				class="h-6"
			/>
			<span class="text-xs font-thin">beta.202303</span>
		</div>
		<div class="news">
			<a href="#">鲸云网络: 最新添加游戏多多自走棋</a>
		</div>
		<div class="user">
			<span>{{ store.state.user.username }}</span>
			<b class="green">{{ store.state.user.wallet.coin }}鲸币</b>
			<a-button type="link" class="header-button" @click="quit"
				>退出登录</a-button
			>
		</div>
	</header>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import { io } from "socket.io-client";
// import { onMounted } from "vue";
export default {
	name: "TheAdminHeader",
	setup() {
		const store = useStore();
		const router = useRouter();
		// 声明 socket
		// const socket = io("http://localhost:8533", {
		//   withCredentials: true,
		//   extraHeaders: {
		//     "tis-header": "tis",
		//     auth: "store.state.token"
		//   }
		// });

		// socket.on("checkResult", payload => {
		//   console.log(payload);
		// });

		// socket.on("disconnect", payload => {
		//   console.log("disconnected");
		//   console.log(payload);
		// });

		// function check() {
		//   socket.emit("check", store.state.token);
		// }

		// onMounted(() => {
		//   console.log("socket connect");
		//   // 监听
		//   socket.on("connect", () => {
		//     console.log(socket.id);
		//     if (socket.connected) {
		//       console.log("connected");
		//       socket.emit("msgToServer");
		//     }
		//   });
		// });

		async function quit() {
			store.commit("quit");
			await router.push("/");
		}
		return {
			store,
			quit,
			// check
		};
	},
};
</script>

<style scoped>
.header {
	position: fixed;
	top: 0;
	display: flex;
	height: 50px;
	background: #20252a;
	width: 100%;
	line-height: 50px;
	z-index: 999;
	color: #ffffff;
}
.logo {
	width: 20%;
	max-width: 200px;
	min-width: 100px;
	padding-left: 20px;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.logo span {
	padding-left: 10px;
}
.news {
	flex: 1;
	padding-left: 40px;
}
.news a {
	text-decoration: underline;
	color: #0366d6;
}
.user {
	margin-right: 20px;
}
.user span {
	margin-right: 10px;
}
.header-button {
	color: #586069;
}
.user .green {
	color: #2ea44f;
}
@media only screen and (max-width: 768px) {
	.news {
		display: none;
	}
	.user {
		flex: 1;
		text-align: right;
	}
}
</style>
