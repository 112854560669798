<template>
	<div class="user">
		<module-title title="用户管理">
			<a-dropdown>
				<template #overlay>
					<a-menu>
						<a-menu-item key="all"> 全部用户 </a-menu-item>
						<a-menu-item key="admin"> 管理员 </a-menu-item>
						<a-menu-item key="agency"> 代理 </a-menu-item>
					</a-menu>
				</template>
				<a-button>
					全部用户
					<DownOutlined />
				</a-button>
			</a-dropdown>
		</module-title>
		<div class="operations">
			<a-button type="primary" class="button" @click="createAgencyShow = true">
				<PlusOutlined />
				新建代理
			</a-button>
			<a-button class="button">
				<CloudSyncOutlined />
				刷新
			</a-button>
			<!--搜索用户-->
			<a-input-search
				style="width: 200px"
				v-model:value="searchRef"
				allowClear
				@search="searchUser"
			></a-input-search>
		</div>
		<div class="list">
			<a-table
				:columns="columns"
				:data-source="users"
				:row-key="(record) => record.id"
				:loading="listLoading"
				size="middle"
				:pagination="{
					position: ['topRight', 'bottomRight'],
					current: page,
					pageSize: pageSize,
					total: total,
				}"
				@change="pageChange"
			>
				<template #bodyCell="{ column, record }">
					<template v-if="column.index === 'real'">
						{{ record.real_name === 0 ? "未认证" : "已认证" }}
					</template>
					<template v-if="column.index === 'role'">
						{{ record.role === "normal" ? "普通用户" : "代理|管理" }}
					</template>
					<template v-if="column.index === 'price'">
						{{ record.expense_datacenter }} | {{ record.expense_home }} |
						{{ record.expense_custom }} | {{ record.expense_edge }}
					</template>
					<template v-if="column.index === 'coin'">
						{{ record.wallet.coin }}
						<span class="text-red-600">{{
							record.wallet.state === WalletState.BLOCK_RECHARGE
								? "禁止充值"
								: ""
						}}</span>
					</template>
					<template v-if="column.index === 'income'">
						{{ record.wallet.coin_income }}
					</template>
					<template v-if="column.index === 'expend'">
						{{ record.wallet.coin_expend }}
					</template>
					<template v-if="column.index === 'agent'">
						{{ record.agent === null ? "无" : record.agent.username }}
					</template>
					<template v-if="column.index === 'action'">
						<a-button
							type="link"
							class="action-button"
							@click="
								transferRef.show = true;
								transferRef.userId = record.id;
							"
						>
							转账
						</a-button>
						<a-button
							type="link"
							class="action-button"
							@click="
								rechargeAgencyShow = true;
								rechargeUserId = record.id;
							"
						>
							充值
						</a-button>
						<router-link
							class="action-button"
							:to="{
								path: '/admin/account_by_user',
								query: { id: record.id, username: record.username },
							}"
						>
							节点账户
						</router-link>
						<a-button
							type="link"
							class="action-button"
							@click="
								changeUserPasswordShow = true;
								changeUserPasswordRef.userId = record.id;
							"
						>
							修改密码
						</a-button>
						<a-button
							type="link"
							@click="changeUserState(record.id, 'block')"
							class="action-button"
							v-if="record.state === 'normal'"
						>
							禁止登录
						</a-button>
						<a-button
							class="action-button"
							v-else
							@click="changeUserState(record.id, 'normal')"
						>
							启用登录
						</a-button>
						<a-button
							type="link"
							class="action-button"
							@click="blockRecharge(record.id)"
							v-if="record.wallet.state !== WalletState.BLOCK_RECHARGE"
						>
							禁止支付
						</a-button>
						<a-button
							type="link"
							class="action-button text-green-600"
							@click="unblockRecharge(record.id)"
							v-else
						>
							恢复支付
						</a-button>
						<a-button
							type="link"
							class="action-button text-green-600"
							@click="
								() => {
									priceRef.id = record.id;
									priceRef.datacenter = record.expense_datacenter;
									priceRef.home = record.expense_home;
									priceRef.custom = record.expense_custom;
									priceRef.edge = record.expense_edge;
									priceShow = true;
								}
							"
						>
							编辑价格
						</a-button>
						<a-button
							type="link"
							class="action-button"
							@click="
								realNameShow = true;
								realNameRef.id = record.id;
							"
						>
							添加实名
						</a-button>
					</template>
				</template>
			</a-table>
		</div>
	</div>
	<!--新建代理--->
	<a-modal
		v-model:visible="createAgencyShow"
		title="新建代理"
		cancel-text="取消"
		ok-text="立即新建"
		:confirm-loading="createAgencyLoading"
		@ok="createAgency"
	>
		<a-form :label-col="{ span: 4 }">
			<a-form-item label="用户名" v-bind="validateInfosAgency.username">
				<a-input
					v-model:value="agencyInfoRef.username"
					style="width: 300px"
					size="large"
				/>
			</a-form-item>
			<a-form-item label="密码" v-bind="validateInfosAgency.password">
				<a-input-password
					v-model:value="agencyInfoRef.password"
					style="width: 300px"
					size="large"
				/>
			</a-form-item>
		</a-form>
	</a-modal>
	<!--代理充值-->
	<a-modal
		v-model:visible="rechargeAgencyShow"
		title="代理充值"
		cancel-text="取消"
		ok-text="确定充值"
		:confirm-loading="rechargeAgencyLoading"
		@ok="rechargeAgency"
	>
		<a-input-number size="large" v-model:value="rechargeAgencyInfoRef.coin" />
	</a-modal>
	<!--转账-->
	<a-modal
		v-model:visible="transferRef.show"
		title="账户转账"
		cancel-text="取消"
		ok-text="确定转账"
		:confirm-loading="transferRef.loading"
		@ok="transfer"
	>
		<a-input-number size="large" v-model:value="transferRef.coin" />
	</a-modal>
	<!--修改密码-->
	<a-modal
		v-model:visible="changeUserPasswordShow"
		title="修改用户密码"
		cancel-text="取消"
		ok-text="确定修改"
		:confirm-loading="changeUserPasswordLoading"
		@ok="changeUserPassword"
	>
		<a-form>
			<a-form-item
				label="新密码"
				v-bind="validateInfosChangeUserPassword.password"
			>
				<a-input size="large" v-model:value="changeUserPasswordRef.password" />
			</a-form-item>
		</a-form>
	</a-modal>
	<!--实名认证-->
	<a-modal
		v-model:visible="realNameShow"
		title="添加实名认证"
		cancel-text="取消"
		ok-text="确定添加"
		@ok="realNameSubmit"
	>
		<a-form>
			<a-form-item label="姓名">
				<a-input size="large" v-model:value="realNameRef.realName" />
			</a-form-item>
			<a-form-item label="身份证号码">
				<a-input size="large" v-model:value="realNameRef.idCard" />
			</a-form-item>
		</a-form>
	</a-modal>
	<!--修改价格-->
	<a-modal
		v-model:visible="priceShow"
		title="修改价格"
		cancel-text="取消"
		ok-text="确定修改"
		@ok="setUserPriceAll"
	>
		<a-form>
			<a-form-item label="数据中心">
				<a-input size="large" v-model:value="priceRef.datacenter" />
			</a-form-item>
			<a-form-item label="家庭">
				<a-input size="large" v-model:value="priceRef.home" />
			</a-form-item>
			<a-form-item label="定制">
				<a-input size="large" v-model:value="priceRef.custom" />
			</a-form-item>
			<a-form-item label="边缘">
				<a-input size="large" v-model:value="priceRef.edge" />
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
import {
	DownOutlined,
	PlusOutlined,
	CloudSyncOutlined,
} from "@ant-design/icons-vue";
import { user } from "@/utils/api";
import { onMounted, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { useForm } from "@ant-design-vue/use";
import { UserState, WalletState } from "@/utils/types";
export default {
	name: "User",
	computed: {
		WalletState() {
			return WalletState;
		},
	},
	components: { ModuleTitle, DownOutlined, PlusOutlined, CloudSyncOutlined },
	setup() {
		const columns = [
			{
				title: "ID",
				dataIndex: "id",
			},
			{
				title: "用户名",
				dataIndex: "username",
			},
			{
				title: "身份",
				index: "role",
			},
			{
				title: "代理",
				index: "agent",
			},
			{
				title: "认证状态",
				index: "real",
			},
			{
				title: "鲸币",
				index: "coin",
			},
			{
				title: "价格(数据中心|家庭|定制)",
				index: "price",
			},
			{
				title: "充值",
				index: "income",
			},
			{
				title: "消费",
				index: "expend",
			},
			{
				title: "创建时间",
				dataIndex: "create_time",
			},
			{
				title: "操作",
				index: "action",
			},
		];
		const users = ref([]);
		// search
		const usersAll = ref([]);
		const listLoading = ref(false);

		// 禁止用户充值
		async function blockRecharge(id) {
			const res = await user.blockRecharge(id);
			message.info("禁止充值成功");
			await getUserList();
		}

		// 恢复用户充值
		async function unblockRecharge(id) {
			await user.unBlockRecharge(id);
			message.info("恢复充值成功");
			await getUserList();
		}

		function pageChange(pagination, filters, sorter) {
			const { current, pageSize } = pagination;
			page.value = current;
			getUserList();
		}

		const page = ref(1);
		const pageSize = ref(20);
		const total = ref(0);
		async function getUserList() {
			listLoading.value = true;
			const res = await user.listV2(
				page.value,
				pageSize.value,
				UserState.NORMAL
			);
			if (res.status === 200) {
				users.value = res.data.data;
				total.value = res.data.total;
			}
			listLoading.value = false;
		}
		// search user
		const searchRef = ref("");
		async function searchUser() {
			if (searchRef.value === "") {
				await getUserList();
				return;
			}
			const response = await user.search(searchRef.value);
			if (response.status === 200) {
				users.value = response.data;
			}
		}

		const createAgencyShow = ref(false);
		const createAgencyLoading = ref(false);
		const agencyInfoRef = reactive({
			username: "",
			password: "",
		});
		const rulesRef = reactive({
			username: [
				{
					required: true,
					message: "请填写用户名",
				},
			],
			password: [
				{
					required: true,
					message: "请填写密码",
				},
			],
		});
		const {
			resetFields: resetAgency,
			validate: validateAgency,
			validateInfos: validateInfosAgency,
		} = useForm(agencyInfoRef, rulesRef);
		// 修改密码
		const changeUserPasswordRef = reactive({
			userId: "",
			password: "",
		});
		// const rulesChangeUserPassword = reactive({
		//   password: [
		//     {
		//       type: "string",
		//       message: "请正确输入密码"
		//     },
		//     {
		//       max: 15,
		//       message: "密码为6-15个字符"
		//     },
		//     {
		//       min: 6,
		//       message: "密码为6-15个字符"
		//     }
		//   ]
		// });

		// 修改密码
		const {
			resetFields: resetChangeUserPassword,
			validate: validateChangeUserPassword,
			validateInfos: validateInfosChangeUserPassword,
		} = useForm(changeUserPasswordRef, {});

		const changeUserPasswordLoading = ref(false);
		const changeUserPasswordShow = ref(false);
		async function changeUserPassword() {
			validateChangeUserPassword().then(() => {
				changeUserPasswordLoading.value = true;
				user
					.changePassword(
						changeUserPasswordRef.userId,
						changeUserPasswordRef.password
					)
					.then((res) => {
						changeUserPasswordLoading.value = false;
						changeUserPasswordShow.value = false;
						if (res.data.code === 0) {
							message.success(res.data.message);
						} else {
							message.error(res.data.message);
						}
					})
					.catch(() => (changeUserPasswordLoading.value = false));
			});
		}
		// 转账
		const transferRef = reactive({
			show: false,
			loading: false,
			userId: 0,
			coin: 0,
		});
		async function transfer() {
			transferRef.loading = true;
			user
				.transfer(transferRef.userId, transferRef.coin)
				.then(() => {
					transferRef.loading = false;
					transferRef.show = false;
					message.success("转账成功");
					getUserList().then();
				})
				.catch((err) => {
					transferRef.loading = false;
					message.error(err);
				});
		}
		//代理充值
		const rechargeAgencyInfoRef = reactive({
			coin: 1,
		});

		const rechargeAgencyLoading = ref(false);
		const rechargeAgencyShow = ref(false);
		const rechargeUserId = ref("");
		// 代理充值
		async function rechargeAgency() {
			console.log(rechargeUserId.value, rechargeAgencyInfoRef.coin);
			rechargeAgencyLoading.value = true;
			user
				.rechargeAgency(rechargeUserId.value, rechargeAgencyInfoRef.coin)
				.then((res) => {
					rechargeAgencyLoading.value = false;
					rechargeAgencyShow.value = false;
					if (res.data.code === 0) {
						message.success("充值成功");
						getUserList().then();
					} else {
						message.error(res.data.message);
					}
				})
				.catch(() => (rechargeAgencyLoading.value = false));
		}
		/**
		 * 创建代理
		 * @return {Promise<void>}
		 */
		async function createAgency() {
			validateAgency()
				.then((res) => {
					createAgencyLoading.value = true;
					user
						.createAgency(res.username, res.password)
						.then((res) => {
							createAgencyLoading.value = false;
							createAgencyShow.value = false;
							if (res.data.code === 0) {
								message.success(res.data.message);
								getUserList().then();
							} else {
								message.error(res.data.message);
							}
						})
						.catch(() => (createAgencyLoading.value = false));
				})
				.catch();
		}

		// 添加实名认证
		const realNameRef = reactive({
			id: "",
			realName: "",
			idCard: "",
		});
		const realNameShow = ref(false);

		async function realNameSubmit() {
			user
				.addRealName(realNameRef.id, realNameRef.realName, realNameRef.idCard)
				.then(() => {
					message.success("添加实名认证成功");
					realNameShow.value = false;
				})
				.catch(() => {
					message.error("Something wrong");
				});
		}

		// 修改价格
		const priceRef = reactive({
			id: "",
			datacenter: "",
			home: "",
			custom: "",
			edge: "",
		});
		const priceShow = ref(false);

		async function setUserPriceAll() {
			user
				.setPrice({
					id: Number(priceRef.id),
					datacenter: Number(priceRef.datacenter),
					home: Number(priceRef.home),
					custom: Number(priceRef.custom),
					edge: Number(priceRef.edge),
				})
				.then(() => {
					message.success("修改价格成功");
					priceShow.value = false;
					getUserList();
				})
				.catch(() => {
					message.error("修改价格失败");
				});
		}

		// 改变用户状态
		async function changeUserState(id, state) {
			await user.changeState(id, state);
			message.info("操作成功,刷新列表");
			await getUserList();
		}
		onMounted(() => {
			getUserList();
		});
		return {
			columns,
			users,
			createAgencyShow,
			resetAgency,
			validateAgency,
			validateInfosAgency,
			agencyInfoRef,
			createAgency,
			createAgencyLoading,
			listLoading,
			rechargeAgencyInfoRef,
			// validateInfosRechargeAgency,
			rechargeAgencyLoading,
			rechargeAgencyShow,
			rechargeAgency,
			rechargeUserId,
			changeUserPasswordShow,
			changeUserPasswordRef,
			changeUserPasswordLoading,
			validateInfosChangeUserPassword,
			changeUserPassword,
			resetChangeUserPassword,
			searchUser,
			searchRef,
			realNameRef,
			realNameSubmit,
			realNameShow,
			changeUserState,
			pageSize,
			page,
			total,
			getUserList,
			pageChange,
			transferRef,
			transfer,
			blockRecharge,
			unblockRecharge,
			setUserPriceAll,
			priceRef,
			priceShow,
			// resetRechargeAgency
		};
	},
};
</script>

<style scoped>
.action-button {
	color: #0366d6;
	margin-right: 4px;
}
</style>
