<template>
	<div class="search" ref="searchOutside">
		<a-input-search
			placeholder="请输入游戏名称或首字母"
			size="large"
			v-model:value="keyword"
			@change="searchGames"
			@focus="searchResultShow = true"
		/>
		<div class="search-result" v-if="searchResultShow">
			<ul class="games" v-if="result.length > 0">
				<li
					v-for="game in result"
					:key="game.id"
					@click="
						$router.push({
							path: '/admin/shop/order',
							query: { id: game.id, game: game.name },
						})
					"
				>
					{{ game.name }}
				</li>
			</ul>
			<h4 class="search-none" v-else>请输入游戏名称或首字母</h4>
		</div>
	</div>
	<div class="list">
		<div class="keyword">
			<a-button
				type="link"
				class="keyword-button"
				v-for="keyword in keywords"
				:key="keyword"
				size="large"
				@click="getGameList(keyword)"
			>
				{{ keyword }}
			</a-button>
		</div>
		<div class="keyword-title">
			<h1>
				首字母为
				<span>{{ chooseKeyword }}</span>
				的业务类型(游戏)
			</h1>
			<b>共找到{{ games.length }}款</b>
			<span>点击上方切换首字母</span>
		</div>
		<div class="games">
			<a-button
				class="game-button"
				v-for="game in games"
				:key="game.id"
				size="large"
				type="link"
				@click="
					$router.push({
						path: '/admin/shop/order',
						query: { id: game.id, game: game.name },
					})
				"
			>
				{{ game.name }}
			</a-button>
		</div>
	</div>
</template>

<script>
import { keywords } from "@/utils/types";
import { onMounted, ref } from "vue";
import { game } from "@/utils/api";
import { onClickOutside } from "@vueuse/core";
import { message } from "ant-design-vue";

export default {
	name: "ShopGames",
	setup() {
		const keyword = ref("");
		const result = ref([]);
		// 点击外面取消搜索结果框
		const searchOutside = ref(null);
		const searchResultShow = ref(false);
		onClickOutside(searchOutside, () => {
			searchResultShow.value = false;
		});
		const timeOut = ref(1); // 防抖
		async function searchGames() {
			if (!(keyword.value === "")) {
				window.clearTimeout(timeOut.value);
				timeOut.value = window.setTimeout(() => {
					game
						.searchGame(keyword.value)
						.then((res) => {
							if (res.data.code === 0) {
								result.value = res.data.data;
							}
						})
						.catch((err) => message.error(err));
				}, 500);
			} else {
				searchResultShow.value = false;
				result.value = [];
			}
		}

		// 获取游戏列表
		const chooseKeyword = ref("A");
		const games = ref([]);
		async function getGameList(initial) {
			await game
				.getGameList(initial)
				.then((res) => {
					if (res.data.code === 0) {
						chooseKeyword.value = initial;
						games.value = res.data.data;
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => message.error(err));
		}
		onMounted(() => {
			getGameList("A").then();
		});
		return {
			// search
			keyword,
			keywords, // 首字母列表
			result,
			searchGames,
			searchResultShow,
			// 点击
			chooseKeyword,
			games,
			getGameList,
			searchOutside,
		};
	},
};
</script>

<style scoped>
.search {
	position: relative;
	margin: 70px 20px 20px 20px;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
}
.search-none {
	padding: 10px;
}
.search-result {
	position: absolute;
	background: #ffffff;
	z-index: 999;
	left: 0;
	right: 0;
	border: 1px #e4ebf1 solid;
	max-height: 600px;
	overflow: auto;
}
.search-result ul {
	margin: 0;
	padding: 0;
}
.search-result ul li {
	list-style: none;
	margin: 0;
	padding: 10px;
	font-size: 18px;
	cursor: pointer;
}
.search-result ul li:hover {
	background: #f5f7fa;
}
.list {
	max-width: 100%;
}
.list .type-module {
	margin-right: 20px;
}
.list .type-module .type-title {
	border-bottom: 1px #e4ebf1 solid;
}
.keyword-title {
	background: rgb(250, 250, 250);
	margin-top: 10px;
	padding: 10px;
	display: flex;
	align-items: center;
}
.keyword-title b {
	flex: 1;
}
.keyword-title h1 {
	margin: 0 20px 0 0;
	font-size: 16px;
}
.keyword-title h1 span {
	color: #2ea44f;
	padding: 0 10px;
	font-size: 20px;
}
.games {
	padding-bottom: 20px;
}
.game-button {
	font-size: 16px;
	width: 200px;
	margin-top: 20px;
	margin-right: 20px;
	margin-left: 10px;
	text-align: left;
}
.game-button:hover {
	font-weight: 500;
	background-color: #f5f7fa;
}
</style>
