<template>
	<header class="header">
		<module-title title="总览"></module-title>
	</header>
	<button @click="refresh">刷新定制城市列表</button>
	<the-cards />
	<div class="list">
		<node-status />
	</div>
</template>
<script>
import TheCards from "@/components/TheCards";
import NodeStatus from "@/components/NodeStatus";
import ModuleTitle from "@/components/ModuleTitle";
import { useStore } from "vuex";
import { box } from "@/utils/api";
export default {
	name: "AdminOverview",
	components: { ModuleTitle, TheCards, NodeStatus },
	setup() {
		const store = useStore();
		async function refresh() {
			await box.updateCity();
		}
		return {
			store,
			refresh,
		};
	},
};
</script>

<style scoped>
.list {
	margin-top: 20px;
}
</style>
