<template>
	<div class="module">
		<div class="loading" v-if="loadingShow">
			<div class="loading-icon">
				<a-spin size="large" />
				<p>加载可用节点ing....</p>
			</div>
		</div>
		<div v-for="(node, index) in canUseNodes" :key="index" class="group-module">
			<div class="title">
				<h4>
					{{ node.group_name }}
					<b class="green">{{ node.can_use_nodes_count }}</b>
				</h4>
			</div>
			<div class="number">
				<span>采购： </span>
				<a-input-number
					size="large"
					:min="0"
					:max="node.can_use_nodes_count"
					v-model:value="node.select_nodes_count"
				></a-input-number>
			</div>
		</div>
	</div>
</template>

<script>
import { account } from "@/utils/api";
import { onMounted, ref } from "vue";
import { message } from "ant-design-vue";

export default {
	props: ["game_id", "account_id"],
	name: "UsableNodesByGameId",
	setup(props) {
		const canUseNodes = ref([]); // 可以使用的节点
		const loadingShow = ref(true);
		async function getCanUseNodesByGameId(game_id) {
			await account.getCanUseNodesByGameId(game_id).then((res) => {
				loadingShow.value = false;
				if (res.data.code === 0) {
					for (const canUseNode of res.data.data) {
						canUseNodes.value.push({
							group_id: canUseNode.group_id,
							group_name: canUseNode.group_name,
							can_use_nodes_count: canUseNode.can_use_nodes_count,
							select_nodes_count: 0,
						});
					}
				}
			});
		}
		async function addNodesIntoAccount() {
			const orders = [];
			for (const item of canUseNodes.value) {
				if (item.select_nodes_count > 0) {
					orders.push({
						group_id: item.group_id,
						node_count: item.select_nodes_count,
					});
				}
			}
			await account
				.addNodesIntoAccount(props.account_id, orders)
				.then((res) => {
					if (res.data.code === 0) {
						message.success("添加节点成功");
					}
				});
		}
		onMounted(() => {
			getCanUseNodesByGameId(props.game_id).then();
		});
		return {
			canUseNodes,
			loadingShow,
			addNodesIntoAccount,
		};
	},
};
</script>

<style scoped>
.module {
	display: flex;
	flex-wrap: wrap;
	background: #ffffff;
	width: 600px;
	height: 65vh;
}
.group-module {
	display: flex;
	margin: 10px;
	align-items: baseline;
	border-bottom: 1px #e4ebf1 solid;
	width: 45%;
	padding: 5px;
	flex-wrap: wrap;
}
.group-module .title {
	display: flex;
	font-size: 14px;
	align-items: baseline;
	flex: 1;
}
.group-module .title b {
	font-size: 14px;
	font-weight: 400;
	margin-left: 10px;
}
.group-module .number span {
	font-size: 12px;
	color: #586069;
}
.loading {
	width: 100%;
	min-height: 600px;
	display: flex;
	justify-content: center;
	align-content: center;
}
.loading-icon {
	width: 200px;
	text-align: center;
}
.green {
	color: #2ea44f;
	font-size: 18px;
	font-weight: 500;
}
</style>
