<template>
	<module-title title="节点管理"></module-title>
	<div class="operation">
		<a-button class="operation-button">
			<ReloadOutlined @click="getNodeGroupList" />
		</a-button>
		<a-button
			class="operation-button"
			type="primary"
			@click="createNodeGroupShow = true"
		>
			<PlusOutlined />创建节点分组
		</a-button>
		<!--搜索节点-->
		<SearchNode></SearchNode>
	</div>
	<div class="list">
		<a-table
			size="middle"
			:columns="groupColumns"
			:data-source="nodeGroupList"
			:row-key="(record) => record.id"
			:pagination="store.state.pagination"
		>
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'name'">
					<b class="bold">{{ record.name }}</b>
				</template>
				<template v-if="column.key === 'action'">
					<span class="action-link">
						<router-link
							:to="{
								path: '/admin/node_list_group',
								query: { id: record.id, name: record.name },
							}"
							>查看/编辑</router-link
						>
					</span>
				</template>
			</template>
		</a-table>
	</div>
	<!--弹出窗口创建节点分组-->
	<div class="popup-container" v-if="createNodeGroupShow">
		<div class="popup">
			<div class="title">
				<h2>创建节点分组</h2>
				<CloseOutlined @click="createNodeGroupShow = false" />
			</div>
			<div class="content">
				<a-input
					placeholder="请输入节点分组名称"
					size="large"
					v-model:value="createNodeGroupName"
				/>
				<div class="tips">{{ createNodeGroupTips }}</div>
			</div>
			<div class="handle">
				<a-button class="handle-button" @click="createNodeGroupShow = false"
					>取消</a-button
				>
				<a-button
					class="handle-button"
					type="primary"
					@click="createNodeGroup"
					:loading="createNodeGroupButtonLoading"
					>确定</a-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ReloadOutlined,
	PlusOutlined,
	CloseOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { node } from "@/utils/api";
import { ref, onMounted } from "vue";
import ModuleTitle from "@/components/ModuleTitle";
import { useStore } from "vuex";
import SearchNode from "@/components/SearchNode.vue";
export default {
	name: "NodeGroups",
	components: {
		ModuleTitle,
		ReloadOutlined,
		PlusOutlined,
		CloseOutlined,
		SearchNode,
	},
	setup() {
		const store = useStore();
		// 节点分组列表
		const groupColumns = [
			{
				title: "ID",
				dataIndex: "id",
			},
			{
				title: "名称",
				key: "name",
			},
			{
				title: "节点数量",
				dataIndex: "node_count",
			},
			{
				title: "创建时间",
				dataIndex: "create_time",
			},
			{
				title: "操作",
				key: "action",
			},
		];
		const createNodeGroupShow = ref(false);
		const createNodeGroupName = ref("");
		const createNodeGroupButtonLoading = ref(false);
		const createNodeGroupTips = ref(
			"请尽量精确描述节点分组名称,名称会显示至用户界面。"
		);

		const nodeShow = ref(false);
		const nodeGroupShow = ref(true);
		/**
		 * 新增节点分组
		 */
		async function createNodeGroup() {
			if (createNodeGroupName.value === "") {
				createNodeGroupTips.value = "节点分组名称不能为空";
			} else {
				createNodeGroupButtonLoading.value = true;
				const data = await node
					.createNodeGroup(createNodeGroupName.value)
					.then((res) => res.data);
				if (data.code === 0) {
					createNodeGroupButtonLoading.value = false;
					createNodeGroupShow.value = false;
					message.success("新增节点分组成功");
				} else {
					createNodeGroupButtonLoading.value = false;
					createNodeGroupTips.value = "节点分组名称不能重复，请重新填写";
					message.error(data.message);
				}
			}
		}

		// 获取节点分组列表
		const nodeGroupList = ref([]);
		async function getNodeGroupList() {
			const data = await node.getNodeGroupList().then((res) => res.data);
			if (data.code === 0) {
				nodeGroupList.value = data.data;
			}
		}

		// 替换分组id
		function replaceGroupId(id) {
			for (const nodeGroup of nodeGroupList.value) {
				if (nodeGroup.id === id) {
					return nodeGroup.name;
				}
			}
		}
		onMounted(() => {
			getNodeGroupList();
		});
		return {
			store,
			createNodeGroup,
			createNodeGroupShow,
			createNodeGroupName,
			createNodeGroupTips,
			createNodeGroupButtonLoading,
			nodeGroupList,
			nodeShow,
			nodeGroupShow,
			groupColumns,
			getNodeGroupList,
			replaceGroupId,
		};
	},
};
</script>

<style scoped>
.info {
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	padding-bottom: 0;
}
.info .tabs {
	display: flex;
}
.info .tabs .tab {
	margin-top: 20px;
	padding: 8px 12px;
	background: #d5e0e9;
	color: #586069;
	margin-right: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	font-size: 14px;
	font-weight: 500;
}
.info .tabs .active {
	background: #ffffff;
	color: #586069;
}
.popup-container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(32, 33, 36, 0.6);
}
.popup {
	margin-top: 200px;
	background: #ffffff;
	width: 500px;
}
.popup .title {
	display: flex;
	align-items: center;
	background: #141f29;
	padding: 10px 20px;
	color: #ffffff;
}
.popup .title h2 {
	color: #ffffff;
	font-size: 16px;
	flex: 1;
	margin: 0;
}
.popup .content {
	padding: 20px;
}
.popup .handle {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px;
	background: #f5f7fa;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.popup .handle .handle-button {
	margin-right: 10px;
}
.popup .tips {
	margin-top: 10px;
	font-size: 12px;
	color: #586069;
	padding: 0;
}
.popup .popup-item {
	margin-bottom: 10px;
}
.action-link a {
	color: #2ea44f;
	text-decoration: none;
}
.bold {
	font-weight: 500;
	font-size: 14px;
}

.operation {
	display: flex;
}
</style>
