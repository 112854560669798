export const types = {
	A: [
		"阿瓦隆手游",
		"阿拉德之怒手游",
		"爱爱精灵游戏",
		"爱尚斗地主",
		"安美健康",
		"暗黑破坏神3",
		"暗黑崛起",
		"傲游",
		"澳亚棋牌",
	],
	B: [
		"B5对战平台",
		"巴士电玩手游",
		"霸王合击",
		"白金岛",
		"百度贴吧",
		"百家乐",
		"百家乐手游",
		"百乐门手游",
		"百灵手游",
		"百人金花手游",
		"百战天下",
		"保卫罗宾",
		"宝博棋牌手游",
		"宝博手游",
		"爆裂天空",
		"贝贝棋牌",
		"贝贝游戏大厅",
		"贝拉传说台服",
		"崩坏3手游",
		"比心",
		"碧蓝航线",
		"边境之旅手游",
		"兵王",
		"冰雪复古手游",
		"波哥散线",
		"波克捕鱼手游",
		"博彩手游",
		"博彩页游",
		"博雅棋牌",
		"捕鱼大亨",
		"捕鱼来了",
		"捕鱼乐翻天",
		"捕鱼页游",
		"不灭OL",
		"部落冲突手游",
		"哔哩哔哩",
	],
	C: [
		"CP彩票",
		"苍穹变",
		"沧月神途吧",
		"测试",
		"测试专用",
		"超变战神传奇",
		"超快排",
		"超星-网课",
		"郴州棋牌",
		"成吉思汗3",
		"成吉思汗怀旧",
		"诚信棋牌",
		"赤月传说9377",
		"楚留香手游",
		"穿越火线",
		"穿越火线手游",
		"传奇2",
		"传奇3",
		"传奇霸业",
		"传奇霸业手游",
		"传奇荣耀",
		"传奇世界(手游)",
		"传奇私服(手游)",
		"传奇天下",
		"传奇外传",
		"传奇无双",
		"传奇永恒",
		"传奇正传",
		"传世复古版手游",
		"传说奇迹",
		"创世战车",
		"藏地传奇",
		"长龙捕鱼手游",
	],
	D: [
		"DNF(地下城与勇士)",
		"Dofus",
		"DOTA2",
		"大裁决",
		"大国",
		"大壕娱乐城",
		"大话2免费",
		"大话西游2",
		"大话西游3",
		"大话西游手游",
		"大乐电玩手游",
		"大神棋牌",
		"大宋",
		"大唐无双",
		"大唐无双2",
		"大唐无双手游",
		"大天使之剑",
		"弹弹堂手游",
		"刀剑2",
		"刀剑斗神手游",
		"刀剑英雄",
		"道王",
		"道无边手游",
		"德扑圈",
		"德友圈手机版",
		"德州伙牌",
		"德州扑克",
		"地城英雄",
		"地城之光",
		"第5人格手游",
		"顶尖神途手游",
		"抖音",
		"斗破苍穹手游",
		"斗仙",
		"斗鱼",
		"斗战神",
		"独上西楼",
		"多乐手游",
		"夺宝传世",
		"巅峰娱乐手游",
	],
	E: ["EVE"],
	F: [
		"Facebook",
		"FIFA19国际服",
		"FIFA20",
		"FIFA3",
		"FIFA3手游",
		"番茄棋牌手游",
		"范特西篮球经理",
		"方舟",
		"放置奇兵手游",
		"飞龙在天",
		"飞扬神途",
		"封印者",
		"枫之谷手游",
		"枫之谷网页版",
		"风暴魔域手游",
		"风采永恒聊天",
		"风流王爷手游",
		"疯狂90秒",
		"凤凰棋牌",
		"复古传奇手游",
		"复古传奇手游合集",
		"富聊",
	],
	G: [
		"GL",
		"Google",
		"盖世豪侠",
		"攻城掠地",
		"攻城略地",
		"公主连结手游",
		"共生币",
		"共生币手游",
		"古剑奇谭",
		"瓜瓜棋牌手游",
		"挂号",
		"怪物猎人",
		"光明大陆",
		"光明大陆手游",
		"光明勇士",
		"光明勇士手游",
		"广东麻将手游",
		"贵州优品",
		"果盘手游平台",
	],
	H: [
		"哈狗手游",
		"哈哈棋牌",
		"海盗来了",
		"海盗王",
		"海之乐章",
		"韩国传奇",
		"汉王纷争手游",
		"航海王燃烧意志手游",
		"豪利页游",
		"禾动小游戏",
		"和平精英手游",
		"黑河麻将",
		"黑金",
		"黑色传奇",
		"红警手游",
		"狐online",
		"狐狸三国",
		"虎牙直播",
		"互点宝",
		"花与剑手游",
		"画皮世界",
		"欢乐斗棋牌手游",
		"欢乐麻将",
		"欢乐拼三张",
		"幻剑神魔手游",
		"幻想三国手游",
		"幻想神域",
		"幻想神域港",
		"幻想神域手游",
		"幻想神域台",
		"荒野求生手游",
		"黄金岛",
		"皇冠赢三张手游",
		"皇者手游",
		"魂域min",
		"魂之猎手",
		"混沌起源手游",
		"火枪纪元",
		"火山视频",
		"火星棋牌",
	],
	I: ["IE"],
	J: [
		"J捕鱼手游",
		"积目",
		"激战2",
		"吉尔塔斯",
		"极光世界",
		"集换英雄",
		"集结号",
		"集结号手游",
		"疾风之刃",
		"剑魂OL",
		"剑灵",
		"剑灵台服",
		"剑破苍穹",
		"剑三手游",
		"剑三台服",
		"剑网3指尖江湖手游",
		"剑侠情缘2",
		"剑侠情缘网络3",
		"剑与远征",
		"江湖",
		"江湖行",
		"街机大师",
		"金博棋牌",
		"金苹果彩票",
		"金星棋牌",
		"劲舞时代手游",
		"京东",
		"惊天动地",
		"精灵Q传",
		"精灵盛典手游",
		"九九电玩",
		"九天神话",
		"九妖千年",
		"九阴手游",
		"九阴手游台服",
		"九阴真经",
		"九游棋牌",
		"聚亨捕鱼",
		"绝地反击",
	],
	K: [
		"KK棋牌",
		"口袋德州手游",
		"口袋西游手游",
		"酷狗游戏",
		"快乐三张",
		"快乐砸金花",
		"快手直播",
		"狂神斗牛",
	],
	L: [
		"来自群聊",
		"赖子棋牌",
		"蓝月传奇(手游)",
		"蓝月棋牌",
		"蓝月至尊手游",
		"烂西瓜棋牌",
		"狼人杀手游",
		"老猫阅读",
		"乐享棋牌手游",
		"雷霆之怒",
		"厘米专线",
		"黎明之光",
		"联众棋牌",
		"恋战",
		"聊呗",
		"列王的纷争",
		"烈火屠龙页游",
		"烈焰苍穹",
		"烈焰传奇",
		"猎鱼达人3D",
		"猎鱼达人手游",
		"猎鱼岛",
		"零纪元",
		"灵魂回响",
		"灵境杀戮手游",
		"灵山奇缘",
		"灵域",
		"流放之路",
		"流放之路台服",
		"流量精灵",
		"流氓暗道",
		"流亡暗道",
		"流亡暗道台服",
		"流亡之路台服",
		"六间房",
		"龙城棋牌手游",
		"龙魂时刻",
		"龙界争霸",
		"龙门虎将",
		"龙神大陆",
		"龙腾传世手游",
		"龙腾世界",
		"龙武",
		"龙之谷",
		"龙之谷手游",
		"炉石传说",
		"炉石传说手游",
		"鹿鼎记",
		"绿色征途",
		"乱斗西游2",
		"乱世王者",
		"乱世英雄",
		"轮回ol",
		"洛汗M台服手游",
		"洛奇英雄传",
		"洛奇英雄手游",
		"洛神",
		"撸彩金手游",
	],
	M: [
		"Mir2",
		"M视频",
		"麻辣江湖",
		"冒泡三国手游",
		"冒险岛1",
		"冒险岛2",
		"冒险岛国际服",
		"美服全民奇迹",
		"萌兽学院",
		"梦幻家园",
		"梦幻龙族",
		"梦幻三国",
		"梦幻隋唐",
		"梦幻西游三维版",
		"梦幻西游手游(桌面版2)",
		"梦幻桌面版",
		"梦幻桌面版2",
		"梦回西游",
		"梦千年ol",
		"梦三国",
		"梦想世界",
		"迷你传奇",
		"迷雾手游",
		"米乐汇棋牌",
		"灭神(网页)",
		"命运初始手游",
		"摩擦",
		"魔盒微阅",
		"魔力宝贝",
		"魔灵召唤手游",
		"魔兽村",
		"魔兽世界国服",
		"魔侠传",
		"魔域",
		"魔域归来手游",
		"末日进化手游",
		"陌陌",
	],
	N: [],
	O: [],
	P: [],
	Q: [
		"将神",
		"将夜OL手游",
		"QQ",
		"QQ飞车",
		"QQ华夏手游",
		"QQ欢乐斗地主",
		"QQ幻想世界",
		"QQ降龙",
		"QQ空间",
		"QQ三国",
		"QQ仙灵",
		"QQ仙侠传",
		"QQ新寻仙",
		"QQ英雄岛",
		"QQ游戏大厅",
		"QQ炫舞",
		"QQ炫舞手游",
		"QT语音",
		"七日之都手游",
		"棋牌",
		"棋牌618",
		"奇迹mu",
		"奇迹MU手游",
		"奇迹sf",
		"奇迹觉醒",
		"奇迹之剑",
		"奇迹重生",
		"奇迹最强者",
		"齐齐乐棋牌",
		"骑士",
		"骑士之心",
		"企鹅电竞",
		"企鹅直播",
		"千牛工作台",
		"千侠传",
		"抢购",
		"抢号",
		"切换游戏",
		"亲朋打旋",
		"亲亲游戏大厅",
		"擒王ol",
		"青鹏棋牌",
		"青云传手游",
		"青云决2手游",
		"青云决手游",
		"晴空物语",
		"趣头条模拟器",
		"趣玩捕鱼手游",
		"趣友麻将手游",
		"去嗨皮",
		"权力的游戏手游",
		"全民K歌手游",
		"全民岛主手游",
		"全职猎手手游",
		"倩女幽魂(手游)",
	],
	R: [
		"R2",
		"RuneScape",
		"热血传奇(归来续章百区)",
		"热血传奇手游",
		"热血传说",
		"热血帝王手游",
		"热血江湖",
		"热血江湖手游",
		"热血江湖私服",
		"热血战歌",
		"人人棋牌",
		"荣耀棋牌手游",
	],
	S: [
		"率土之滨",
		"Shield",
		"三国群英手游",
		"三国如龙手游",
		"三国杀",
		"三国志手游",
		"三人二七王",
		"散人传奇手游",
		"丧尸之战手游",
		"扫雷",
		"上古世纪国服",
		"上古世纪台服",
		"射雕zero",
		"神雕ol",
		"神雕侠侣",
		"神鬼传奇",
		"神话纪元",
		"神迹",
		"神魔大陆",
		"神魔大陆手游",
		"神泣",
		"神途",
		"神武2",
		"神武3",
		"神武4(手游)",
		"神武手游3",
		"神谕之战",
		"盛世遮天页游",
		"圣城棋牌",
		"圣斗士星矢",
		"圣王",
		"石器时代手游",
		"时空裂痕",
		"时空猎人手游",
		"逝水奇迹",
		"噬魂ol手游",
		"侍魂胧月传说手游",
		"守望黎明手游",
		"守望先锋",
		"蜀门",
		"蜀门手游",
		"蜀山缥缈录",
		"刷票",
		"水浒Q传手游",
		"私服传奇",
		"死神",
		"搜狗浏览器",
		"索沛cs",
		"狩龙战纪",
	],
	T: [],
	U: [],
	V: ["V4手游"],
	W: [
		"完美世界国服/台服手游(端游)",
		"万股额",
		"万国觉醒手游",
		"万王之王3D",
		"王国纪元手游",
		"王者国度",
		"王者荣耀",
		"王者游戏",
		"网页",
		"网页游客",
		"网易",
		"旺百家PK10",
		"忘仙",
		"忘仙手游",
		"微乐手游",
		"微信",
		"问道(端游/手游)",
		"问道海外版",
		"我的使命",
		"我的王朝手游",
		"我叫MT3",
		"沃土",
		"无敌神器",
		"武魂",
		"武林外传",
		"武林主宰手游",
		"武神变手游",
		"悟空传",
	],
	X: [
		"降魔OL",
		"西部大陆手游",
		"西楚霸王",
		"西游无双",
		"西域风云",
		"西域风云手游",
		"希望ol",
		"喜乐棋牌",
		"侠武",
		"侠众道手游",
		"仙变ol",
		"仙剑奇侠传四手游",
		"仙剑手游",
		"仙境传奇",
		"仙境传说",
		"仙境传说手游",
		"仙侠世界",
		"闲鱼助手手游",
		"线上电玩城",
		"襄阳同城游戏",
		"小红书",
		"小猴子",
		"携程旅行手游",
		"新百家乐",
		"新飞飞",
		"新海盗王",
		"新剑侠情缘",
		"新剑侠世界",
		"新江湖",
		"新浪微博",
		"新墨香",
		"新墨香台服",
		"新蜀门",
		"新水浒Q传",
		"新丝路传说",
		"新天下无双",
		"新倚天2",
		"新英雄",
		"新倩女幽魂端游",
		"心享",
		"星尘传说",
		"星界神话",
		"熊猫TV",
		"熊猫麻将手游",
		"修仙物语",
		"修真诀手游",
		"轩辕传奇",
		"轩辕传奇手游",
		"学生赚手游",
		"雪鹰领主手游",
		"寻龙记",
		"寻仙手游",
		"逍遥情缘",
	],
	Y: [
		"谷歌",
		"YY引流",
		"YY语音",
		"亚马逊",
		"炎黄大陆",
		"演唱汇手游",
		"雁荡山茶苑",
		"妖精的尾巴手游",
		"一刀传奇",
		"一刀传世手游",
		"一梦江湖",
		"一人之下手游",
		"伊岐那邪",
		"亿酷棋牌",
		"阴阳师手游",
		"英豪天龙",
		"英雄传奇",
		"英雄道",
		"英雄联盟",
		"英雄年代",
		"英雄三国",
		"盈彩彩票",
		"永恒纪元手游",
		"永恒之塔",
		"永胜棋牌手游",
		"勇者斗恶龙",
		"悠游游戏中心",
		"鱼丸手游",
		"玉龙在天手游",
		"御剑红尘",
		"御龙手游",
		"御龙在天",
		"育碧",
		"预言ol",
		"原神系列",
		"原始传奇",
		"缘缘语音",
		"远征",
		"云海棋牌",
		"云海游戏",
		"云上城之歌手游",
	],
	Z: [
		"择天记手游",
		"斩魂",
		"斩千军",
		"战国破坏神",
		"战火与秩序",
		"战火秩序手游",
		"战双帕弥什手游",
		"战西游",
		"真龙霸业手游",
		"真龙主宰",
		"镇魂曲",
		"镇魔曲手游",
		"征三国",
		"征途",
		"征途手游",
		"支付宝",
		"中华复古传奇",
		"猪来了手游",
		"诛仙系列",
		"诛邪",
		"逐鹿神途",
		"注册",
		"坠星大陆",
		"卓越之剑2",
		"自由幻想",
		"自由幻想手游",
		"自由篮球",
		"足球彩",
		"足球经理",
		"醉逍遥",
		"最终幻想14",
	],
};
export const keywords = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
];

export class NodeStatus {
	time: Date;
	groups: NodeGroup[];
	results: [];

	constructor(time: Date, groups: NodeGroup[], results: []) {
		this.time = time;
		this.groups = groups;
		this.results = results;
	}
}

export class NodeGroup {
	// 分组ID
	id: number;
	// 分组名称
	name: string;
	// 节点id
	nodes: number[];
	// 节点数量
	node_count: number;
	// 创建时间
	create_time: Date;
	// 修改时间
	update_time: Date;
	// 检查节点结果字段
	enable?: number;
	disable?: number;
	results?: unknown[];
	users?: number;

	constructor(
		id: number,
		name: string,
		nodes: number[],
		node_count: number,
		create_time: Date,
		update_time: Date
	) {
		this.id = id;
		this.name = name;
		this.nodes = nodes;
		this.node_count = node_count;
		this.create_time = create_time;
		this.update_time = update_time;
	}
}

export class NodeStatusResult {
	id: number;
	ip: string;
	group: number;
	data: never;
	status: boolean;

	constructor(
		id: number,
		ip: string,
		group: number,
		data: never,
		status: boolean
	) {
		this.id = id;
		this.ip = ip;
		this.group = group;
		this.data = data;
		this.status = status;
	}
}

export interface BillListResponse {
	page: number;
	limit: number;
	total: number;
	data: [];
}

export interface GatewayInfo {
	information: string;
	customer: string;
	macaddr: string;
	enable: boolean;
	local:
		| undefined
		| {
				id: number;
				ip: string;
				macaddr: string;
		  };
}

export interface GatewayConfig {
	timestamp: string;
	rulesCount: number;
	rulesCountInUse: number;
	edgesCountInUse: number;
	ruleGroupByProvince: ruleGroupByProvince[];
}

export interface ruleGroupByProvince {
	province: string;
	city: string;
	rules: GatewayRule[];
}

export interface GatewayRule {
	table: number;
	enable: boolean;
	edge: string[];
	network: string[];
	cityhash: string;
}

export interface NodeCity {
	nodes: string[];
	id: number;
	province: string;
	city: string;
	hash: string;
	create_time: string;
	update_time: string;
	count: number;
	tel: number;
	uni: number;
	selectTel: number;
	selectUni: number;
}

export interface NodeProvince {
	cities: NodeCity[];
	province: string;
}

export interface NodeInfo {
	id: number;
	group_id: number;
	city_id: number;
	gateway_id: number;
	edges: string[];
	ip: string;
	selected?: boolean;
	control_ip: string;
	control_port: string;
	control_id: string;
	crete_time: string;
	update_time: string;
}

export enum IspType {
	All = "all",
	Telecom = "telecom",
	Unicom = "unicom",
}

export enum UserState {
	NORMAL = "normal",
	BLOCK = "block",
}

export enum WalletState {
	NORMAL = "normal",
	// 禁止充值
	BLOCK_RECHARGE = "block_recharge",
	FREEZE = "freeze",
}

export enum MiddleStatus {
	ONLINE = "online",
	OFFLINE = "offline",
	PENDING = "pending",
}

export interface Config {
	id: number;
	serviceId: string;
	middle: Middle;
	device?: Device;
	createTime: Date;
	updateTime: Date;
}

export interface Middle {
	id: string;
	ip: string;
	token: string;
	port: number;
	noisePublicKey: string;
	status: MiddleStatus;
	available: number;
	devices: Device[];
	configs: Config[];
	remark: string;
	updateTime: Date;
	createTime: Date;
}

export interface Device {
	id: number;
	deviceUuid: string;
	ip?: string;
	middle?: Middle;
	config?: Config;
	isp: string;
	updateTime: Date;
	createTime: Date;
}

export interface ClientConfig {
	restart: boolean;
	remote_addr: string;
	remote_public_key: string;
	token: string;
	timestamp: number;
	service_id: string;
}

export interface DeviceWithStatus extends Device {
	last_request_timestamp: number;
	last_restart_timestamp: number;
	online_minutes: number;
	online: boolean;
	clientConfig: ClientConfig;
	black: boolean;
	location: Location;
}

/**
 * export class Location {
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    country: string;

    @Column()
    countryCode: string;

    @Column()
    province: string;

    @Column()
    provinceCode: string;

    @Column()
    city: string;

    @Column()
    cityCode: string;

    @Column()
    districts: string;

    @Column()
    @Index()
    districtCode: string;

    @CreateDateColumn()
    createdAt: Date;

    @UpdateDateColumn()
    updatedAt: Date;
}
 */
export interface Location {
	id: number;
	country: string;
	countryCode: string;
	province: string;
	provinceCode: string;
	city: string;
	cityCode: string;
	districts: string;
	districtCode: string;
}

export interface MiddleNewOrUpdateDto {
	ip: string;
	token: string;
	port: number;
	noise: string;
	remark?: string;
}

export interface MappingData {
	id: string;
	name: string;
	service_id: string;
}

/**
 * export class Middle {
    @PrimaryGeneratedColumn('uuid')
    id: string;

    @Column()
    ip: string;

    @Column()
    token: string;

    @Column()
    port: number;

    @Column()
    noisePublicKey: string;

    @Column({
        type: 'enum',
        enum: MiddleStatus,
        default: MiddleStatus.PENDING,
    })
    status: MiddleStatus;

    @Column()
    remark: string;

    @OneToMany(() => Device, (device) => device.middle)
    devices: Device[];

    @OneToMany(() => Config, (config) => config.middle)
    configs: Config[];

    @UpdateDateColumn()
    updateTime: Date;

    @CreateDateColumn()
    createTime: Date;
}
 */

/**
 * export class Node {
	@PrimaryGeneratedColumn()
	id: number;

	@Column()
	group_id: number;

	// location id default 0
	@Column({
		default: 0,
	})
	city_id: number;

	@Column({ default: 0 })
	gateway_id: number;

	@Column("simple-array")
	edges: string[] = [];

	@Column()
	ip: string;

	@Column()
	control_ip: string;

	@Column()
	control_port: string;

	@Column()
	control_id: string;

	@CreateDateColumn()
	create_time: Date;

	@UpdateDateColumn()
	update_time: Date;

	// 节点状态
	@Column({
		type: "enum",
		enum: NodeState,
		default: NodeState.UNUSED,
	})
	state: NodeState;

	// 节点类型
	@Index()
	@Column({
		type: "enum",
		enum: NodeType,
		default: NodeType.DC,
	})
	type: NodeType;

	// 运营商
	@Column({
		type: "enum",
		enum: IspType,
		default: IspType.UNKNOWN,
	})
	isp: IspType;

	// 对应边缘节点的配置 ID
	@Column({ default: 0 })
	config_id: number;
}
 */

// 节点状态
export enum NodeState {
	// 正常
	NORMAL = "normal",
	// 未启用
	UNUSED = "unused",
	// 幽灵
	GHOST = "ghost",
}

// 节点类型
export enum NodeType {
	// 未知
	UNKNOWN = "unknown",
	// 数据中心
	DC = "dc",
	// 家庭宽带
	HOME = "home",
	// 定制
	CUSTOM = "custom",
	// 边缘
	EDGE = "edge",
}

export interface Node {
	id: number;
	group_id: number;
	city_id: number;
	gateway_id: number;
	edges: string[];
	ip: string;
	control_ip: string;
	control_port: string;
	control_id: string;
	create_time: Date;
	update_time: Date;
	state: NodeState;
	type: NodeType;
	isp: IspType;
	config_id: number;
}

export interface EdgeNode extends Node {
	city: NodeCity;
}

export enum AccountType {
	// DC
	DC = "dc",
	// 家庭节点
	HOME = "home",
	// 边缘节点
	EDGE = "edge",
	// 定制节点
	CUSTOM = "custom",
}

export interface LogHost {
	name: string;
	ip: string;
	zid?: string;
	group?: string;
}

export interface EdgeGroup {
	controlIp: string;
	controlIdPattern: string;
	total: number;
}
