<template>
	<div class="test">
		<h1>Http代理测试</h1>
		<a-form layout="vertical">
			<a-form-item v-bind="validateInfos.host">
				<a-input
					placeholder="输入host主机"
					size="large"
					v-model:value="modelRef.host"
				/>
			</a-form-item>
			<a-form-item v-bind="validateInfos.port">
				<a-input
					placeholder="端口"
					size="large"
					v-model:value="modelRef.port"
				/>
			</a-form-item>
			<a-form-item v-bind="validateInfos.protocol">
				<a-input
					placeholder="协议"
					size="large"
					v-model:value="modelRef.protocol"
				/>
			</a-form-item>
			<a-form-item v-bind="validateInfos.username">
				<a-input
					placeholder="用户名"
					size="large"
					v-model:value="modelRef.username"
				/>
			</a-form-item>
			<a-form-item v-bind="validateInfos.password">
				<a-input
					placeholder="密码"
					size="large"
					v-model:value="modelRef.password"
				/>
			</a-form-item>
			<div class="result">
				<h2>{{ result.code === 0 ? "正常" : "超时-不可用" }}</h2>
				<template v-if="result.code === 0">
					<p>IP: {{ result.data.ip }}</p>
					延迟: {{ result.data.delay }} ms
				</template>
			</div>
			<a-form-item>
				<a-button
					type="primary"
					size="large"
					block
					@click="checkForm"
					:loading="testLoading"
				>
					测试
				</a-button>
				<a-button
					size="large"
					block
					style="margin-top: 20px"
					@click="resetForm"
				>
					重置
				</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>
<script>
import { reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { status } from "@/utils/api";

export default {
	setup() {
		const modelRef = reactive({
			host: "",
			port: 28100,
			protocol: "http",
			username: "",
			password: "",
		});
		const { resetFields, validate, validateInfos } = useForm(
			modelRef,
			reactive({
				host: [{ required: true, message: "主机不能为空" }],
				username: [{ required: true, message: "用户名不能为空" }],
				password: [{ required: true, message: "密码不能为空" }],
			})
		);
		const testLoading = ref(false);
		const result = ref({});
		async function checkForm() {
			testLoading.value = true;
			await validate()
				.then(() => {
					status
						.test(
							modelRef.host,
							modelRef.protocol,
							modelRef.port,
							modelRef.username,
							modelRef.password
						)
						.then((res) => {
							testLoading.value = false;
							console.log(res);
							result.value = res.data;
						});
				})
				.catch((err) => console.log(err));
		}
		async function resetForm() {
			await resetFields();
		}

		return {
			modelRef,
			checkForm,
			validateInfos,
			resetForm,
			result,
			testLoading,
		};
	},
};
</script>
<style scoped>
.test {
	width: 500px;
	margin: 80px auto;
}
.result {
	background: #141f29;
	color: #ffffff;
	padding: 10px;
	border-radius: 4px;
	margin-bottom: 20px;
}
.result h2 {
	color: #ffffff;
}
</style>
