<template>
	<div class="accounts">
		<module-title back="1" :title="username + '的节点账户'">
			<a-dropdown>
				<template #overlay>
					<a-menu @click="changeAccountListType">
						<a-menu-item key="renew"> 7天内到期的账户 </a-menu-item>
						<a-menu-item key="all"> 全部节点账户 </a-menu-item>
					</a-menu>
				</template>
				<a-button>
					{{ accountListType }}
					<DownOutlined />
				</a-button>
			</a-dropdown>
		</module-title>
		<div class="tips">
			<a-alert
				message="开通账户出现预检状况,请等待1分钟后自动重新开通。"
				type="info"
			/>
		</div>
		<div class="operations">
			<a-button type="primary" class="button">
				<SisternodeOutlined />
				新建节点账户
			</a-button>
			<a-button class="button" @click="getAccountList">
				<CloudSyncOutlined />
				刷新
			</a-button>
			<a-button class="button" disabled>
				<DisconnectOutlined />
				封禁
			</a-button>
			<a-button class="button" disabled>
				<ThunderboltOutlined />
				检测
			</a-button>
			<!--      <a-button class="button">-->
			<!--        更多操作-->
			<!--        <DownOutlined />-->
			<!--      </a-button>-->
			<div class="search">
				<a-input-search
					placeholder="输入用户账号检索"
					:allow-clear="true"
					style="width: 30%"
					v-model:value="keyword"
					@change="searchKeyword"
				/>
			</div>
		</div>
		<div class="list">
			<a-table
				:columns="columns"
				:data-source="accounts"
				:row-key="(record) => record.id"
				:pagination="store.state.pagination"
				:rowSelection="{ columnWidth: 40 }"
				:rowClassName="
					(record, index) => (index % 2 === 1 ? 'table-striped' : null)
				"
				size="middle"
				:loading="listLoading"
			>
				<template #id="{ record }" class="username">
					<span class="blue">{{ record.username }}</span>
				</template>
				<template #state="{ record }">
					<span class="state normal" v-if="record.state === 'normal'">
						正常
					</span>
					<span class="state previewing" v-if="record.state === 'previewing'">
						预检
					</span>
					<span class="state renew" v-if="record.state === 'renew'">
						过期
					</span>
					<span class="state ghost" v-if="record.state === 'ghost'">
						删除
					</span>
					<span class="state block" v-if="record.state === 'block'">
						封禁
					</span>
				</template>
				<template #nodes="{ record }">
					<span>{{ record.nodes.length }}</span>
				</template>
				<template #time="{ record }">
					{{ record.expire_time }}
				</template>
				<template #action="{ record }">
					<a
						class="action-button"
						@click="$router.push(`/admin/account_info/${record.id}`)"
						type="link"
					>
						查看/导出
					</a>
					<a
						class="action-button"
						@click="$router.push(`/admin/account_nodes_list/${record.id}`)"
						type="link"
					>
						编辑
					</a>
					<a
						class="action-button"
						type="link"
						@click="$router.push(`/admin/account_nodes_test/${record.id}`)"
					>
						检测
					</a>
					<a-popconfirm
						title="您要封禁这个账户么?"
						ok-text="确认封禁"
						cancel-text="取消"
						@confirm="blockAccount(record.id, 'block')"
					>
						<a class="action-button" v-if="record.state !== 'block'"> 封禁 </a>
					</a-popconfirm>
					<a-popconfirm
						title="您要解封这个账户么?"
						ok-text="确认解封"
						cancel-text="取消"
						@confirm="blockAccount(record.id, 'relieve')"
					>
						<a class="action-button" v-if="record.state === 'block'"> 解封 </a>
					</a-popconfirm>
					<a-popconfirm
						title="您要删除这个账户么?"
						ok-text="确认删除"
						cancel-text="取消"
						@confirm="delAccount(record.id)"
					>
						<a class="action-button"> 删除 </a>
					</a-popconfirm>
				</template>
			</a-table>
		</div>
	</div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle";
import {
	DownOutlined,
	DisconnectOutlined,
	ThunderboltOutlined,
	CloudSyncOutlined,
	SisternodeOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { account } from "@/utils/api";
import { message } from "ant-design-vue";
export default {
	name: "AccountByUser",
	components: {
		ModuleTitle,
		DownOutlined,
		DisconnectOutlined,
		ThunderboltOutlined,
		CloudSyncOutlined,
		SisternodeOutlined,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		username: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();
		const columns = [
			{
				title: "ID/名称",
				slots: { customRender: "id" },
			},
			{
				title: "状态",
				slots: { customRender: "state" },
				filters: [
					{ text: "正常", value: "normal" },
					{ text: "预检", value: "previewing" },
					{ text: "过期", value: "expire" },
					{ text: "封禁", value: "block" },
				],
				onFilter: (value, record) => record.state.includes(value),
			},
			{
				title: "类型",
				dataIndex: "game_id",
			},
			{
				title: "节点数",
				slots: { customRender: "nodes" },
				sorter: (a, b) => a.nodes.length - b.nodes.length,
			},
			{
				title: "到期时间",
				// dataIndex: "expire_time",
				slots: { customRender: "time" },
				sorter: (a, b) =>
					new Date(a.expire_time).getTime() - new Date(b.expire_time).getTime(),
			},
			{
				title: "操作",
				slots: { customRender: "action" },
			},
		];

		const accountsDefault = ref([]);
		const accounts = ref([]);
		const keyword = ref("");
		const listLoading = ref(false);
		// 获得账户列表
		async function getAccountList() {
			listLoading.value = true;
			await account.getAccountList("", props.id).then((res) => {
				if (res.data.code === 0) {
					accounts.value = res.data.data;
					accountsDefault.value = res.data.data;
				}
			});
			listLoading.value = false;
		}
		// 获得7天内到期账户列表
		async function getAccountListExpire() {
			listLoading.value = true;
			await account.getAccountList("expire", props.id).then((res) => {
				if (res.data.code === 0) {
					accounts.value = res.data.data;
					accountsDefault.value = res.data.data;
				}
			});
			listLoading.value = false;
		}
		// 封禁账户
		async function blockAccount(id, action) {
			await account
				.blockAccount(id, action)
				.then((res) => {
					if (res.data.code === 0) {
						message.success(`${action}用户成功`);
					} else {
						message.error(res.data.message);
					}
				})
				.catch((err) => message.error(err));
			await getAccountList().then();
		}
		// 删除账户
		async function delAccount(id) {
			await account.deleteAccount(id).then((res) => {
				if (res.data.code === 0) {
					message.success(res.data.message);
					getAccountList().then();
				}
			});
		}

		/**
		 * 筛选关键词
		 */
		function searchKeyword() {
			if (keyword.value === "") {
				accounts.value = accountsDefault.value;
			} else {
				accounts.value = accountsDefault.value.filter((item) => {
					return item.username.indexOf(keyword.value) !== -1;
				});
			}
		}
		// 节点账户选项
		const accountListType = ref("全部节点账户");
		const accountListTypeKey = ref("all");
		async function changeAccountListType(event) {
			if (accountListTypeKey.value === event.key) {
				return true;
			}
			switch (event.key) {
				case "renew":
					await getAccountListExpire().then();
					accountListType.value = "七天内到期账户";
					accountListTypeKey.value = "renew";
					message.success("显示七天内到期账户");
					break;
				case "all":
					await getAccountList().then();
					accountListType.value = "七天内到期账户";
					accountListTypeKey.value = "all";
					message.success("显示全部节点账户");
					break;
				default:
					break;
			}
		}
		// 查询即将到期的用户
		onMounted(() => {
			getAccountList().then();
		});
		return {
			columns,
			accounts,
			delAccount,
			keyword,
			searchKeyword,
			getAccountListExpire,
			getAccountList,
			store,
			blockAccount,
			changeAccountListType,
			accountListType,
			listLoading,
		};
	},
};
</script>

<style scoped>
.accounts {
	min-width: 850px;
}

.operations {
	margin-top: 10px;
	padding: 0 20px;
	display: flex;
	flex-wrap: wrap;
}

.search {
	flex: 1;
	text-align: right;
}

.list {
	background: #ffffff;
	box-shadow: 0 2px 4px 0 rgb(54 58 80 / 20%);
	margin: 10px 20px;
}

.table-striped {
	background-color: rgb(250, 251, 252);
}

.username {
	padding-left: 10px;
}

.blue {
	font-weight: 500;
}

.action-button {
	color: rgb(3, 102, 214);
	margin-right: 10px;
}

.state {
	color: #2ea44f;
}

.previewing {
	color: rgb(251, 188, 4);
}

.block {
	color: rgb(217, 48, 37);
}

.renew {
	color: #0366d6;
}
</style>
